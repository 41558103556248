import DashboardLayout from 'src/views/Dashboard/Layout/DashboardLayout.vue';
import { Route, NavigationGuardNext } from 'vue-router/types/index';
import { useLogiCoredata } from 'src/util/logibase';
import { withWorkplaceRoleGuard } from 'src/util/withWorkplaceRoleGuard';

// 注) これを変えたらviews/Dashboard/Layout/sidebarLinkGroupsも確認すること
const routes = [
  {
    path: '',
    redirect: 'workplace/select',
  },
  {
    path: 'workplace',
    component: DashboardLayout,
    redirect: 'workplace/select',
    children: [
      {
        path: 'select',
        name: 'WorkplaceSelect',
        component: require('src/views/Dashboard/Workplace/Select').default,
      },
    ],
  },
  {
    path: 'workplace',
    component: withWorkplaceRoleGuard(DashboardLayout),
    children: [
      {
        path: ':workplaceId/map',
        name: 'WorkplaceFeatureMap',
        component: require('src/views/Dashboard/Workplace/FeatureMap').default,
      },
      {
        path: ':workplaceId/:date/monthly-board',
        name: 'WorkplaceMonthlyBoard',
        component: require('src/views/Dashboard/Workplace/MonthlyPlan').default,
      },
      {
        path: ':workplaceId/:date/regular-shift',
        name: 'WorkplaceRegularShift',
        component: require('src/views/Dashboard/Workplace/RegularShift').default,
      },
      {
        path: ':workplaceId/:date/daily-board-prep',
        name: 'WorkplaceDailyBoardPrep',
        component: require('src/views/Dashboard/Workplace/PlanBoard').default,
      },
      {
        path: ':workplaceId/:date/daily-board-review',
        name: 'WorkplaceDailyBoardReview',
        component: require('src/views/Dashboard/Workplace/Timeline').default,
      },
      {
        path: ':workplaceId/:date/daily-simulation',
        name: 'WorkplaceDailySimulation',
        component: require('src/views/Dashboard/Workplace/DailySimulation').default,
      },
      {
        path: ':workplaceId/:date/staff-work-plan',
        name: 'WorkplaceStaffWorkPlan',
        component: require('src/views/Dashboard/Workplace/StaffWorkPlan').default,
      },
      {
        path: ':workplaceId/:date/operation-overview',
        name: 'WorkplaceOperationOverview',
        component: require('src/views/Dashboard/Workplace/TimetableTrend').default,
      },
      {
        path: ':workplaceId/:date/rescue-adjustment',
        name: 'WorkplaceAllocationAdjustment',
        component: require('src/views/Dashboard/Workplace/RescueAdjustment').default,
      },
      {
        path: ':workplaceId/performance-board-detail',
        name: 'WorkplacePerformanceBoardDetail',
        component: require('src/views/Dashboard/Workplace/PerformanceBoardDetail').default,
      },
      {
        path: ':workplaceId/performance-board-list',
        name: 'WorkplacePerformanceBoardList',
        component: require('src/views/Dashboard/Workplace/PerformanceBoardList').default,
      },
      {
        path: ':workplaceId/attendance-list',
        name: 'WorkplaceAttendanceList',
        component: require('src/views/Dashboard/Workplace/AttendanceList').default,
      },
      {
        path: ':workplaceId/:date/plan-progress',
        name: 'WorkplacePlanProgress',
        component: require('src/views/Dashboard/Workplace/PlanProgress').default,
      },
      {
        path: ':workplaceId/:date/progress-board',
        name: 'WorkplaceProgressBoard',
        component: require('src/views/Dashboard/Workplace/ProgressBoard').default,
      },
      {
        path: ':workplaceId/background-process-status',
        name: 'WorkplaceBackgroundProcessStatus',
        component: require('src/views/Dashboard/Workplace/BackgroundProcessStatus').default,
      },
      {
        path: ':workplaceId/conversion-programs',
        name: 'WorkplaceConversionPrograms',
        component: require('src/views/Dashboard/Workplace/ConversionPrograms').default,
      },
      {
        path: ':workplaceId/staff-overview',
        name: 'WorkplaceStaffOverview',
        component: require('src/views/Dashboard/Workplace/StaffOverview').default,
      },
      {
        path: ':workplaceId/staff-experience-overview',
        name: 'StaffExperienceOverview',
        component: require('src/views/Dashboard/Workplace/StaffExperienceOverview').default,
      },
      {
        path: ':workplaceId/staff-shift-overview',
        name: 'WorkplaceStaffShiftOverview',
        component: require('src/views/Dashboard/Workplace/StaffShiftOverview').default,
      },
      {
        path: ':workplaceId/settings',
        name: 'SettingsSelect',
        component: require('src/views/Dashboard/Workplace/Settings/Select').default,
      },

      {
        path: ':workplaceId/settings/workplace',
        name: 'SettingsWorkplace',
        component: require('src/views/Dashboard/Workplace/Settings/Workplace').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, `/workplace/${to.params.workplaceId}/settings/workplace`);
        },
      },
      {
        path: ':workplaceId/settings/budget-group',
        name: 'SettingsBudgetGroup',
        component: require('src/views/Dashboard/Workplace/Settings/BudgetGroup').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, `/workplace/${to.params.workplaceId}/settings/budget-group`);
        },
      },

      {
        path: ':workplaceId/settings/staff-agency',
        name: 'SettingsStaffAgency',
        component: require('src/views/Dashboard/Workplace/Settings/StaffAgency').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, `/workplace/${to.params.workplaceId}/settings/staff-agency`);
        },
      },
      {
        path: ':workplaceId/settings/budget-unit',
        name: 'SettingsBudgetUnit',
        component: require('src/views/Dashboard/Workplace/Settings/BudgetUnit').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, `/workplace/${to.params.workplaceId}/settings/budget-unit`);
        },
      },
      {
        path: ':workplaceId/settings/staff-label',
        name: 'SettingsStaffLabel',
        component: require('src/views/Dashboard/Workplace/Settings/StaffLabel').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, `/workplace/${to.params.workplaceId}/settings/staff-label`);
        },
      },
      {
        path: ':workplaceId/settings/staff',
        name: 'SettingsStaff',
        component: require('src/views/Dashboard/Workplace/Settings/Staff').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, `/workplace/${to.params.workplaceId}/settings/staff`);
        },
      },
      {
        path: ':workplaceId/settings/share-settings',
        name: 'SettingsShareSettings',
        component: require('src/views/Dashboard/Workplace/Settings/ShareSettings').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, `/workplace/${to.params.workplaceId}/settings/share-settings`);
        },
      },
      {
        path: ':workplaceId/settings/macro-operation-master',
        name: 'SettingsMacroOperationMaster',
        component: require('src/views/Dashboard/Workplace/Settings/MacroOperationMaster').default,
      },
      {
        path: ':workplaceId/settings/timetable-label',
        name: 'SettingsTimetableLabel',
        component: require('src/views/Dashboard/Workplace/Settings/TimetableLabel').default,
      },
      {
        path: ':workplaceId/settings/timetable-master',
        name: 'SettingsTimetableMaster',
        component: require('src/views/Dashboard/Workplace/Settings/TimetableMaster').default,
      },
      {
        path: ':workplaceId/settings/timetable-group-master',
        name: 'SettingsTimetableGroupMaster',
        component: require('src/views/Dashboard/Workplace/Settings/TimetableGroupMaster').default,
      },
      {
        path: ':workplaceId/settings/workplace-extension',
        name: 'SettingsWorkplaceExtension',
        component: require('src/views/Dashboard/Workplace/Settings/WorkplaceExtension').default,
      },
      {
        path: ':workplaceId/settings/budget-group-extension',
        name: 'SettingsBudgetGroupExtension',
        component: require('src/views/Dashboard/Workplace/Settings/BudgetGroupExtension').default,
      },
      {
        path: ':workplaceId/settings/staff-extension',
        name: 'SettingsStaffExtension',
        component: require('src/views/Dashboard/Workplace/Settings/StaffExtension').default,
      },
      {
        path: ':workplaceId/settings/shift-pattern',
        name: 'SettingsShiftPattern',
        component: require('src/views/Dashboard/Workplace/Settings/ShiftPattern').default,
      },
    ],
  },
  {
    path: 'settings',
    component: DashboardLayout,
    redirect: 'settings/select',
    children: [
      {
        path: 'select',
        name: 'GeneralSettingsSelect',
        component: require('src/views/Dashboard/Settings/Select').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, '/settings/select');
        },
      },
      {
        path: 'user',
        name: 'GeneralSettingsUser',
        component: require('src/views/Dashboard/Settings/User').default,
        beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
          // Prevent access to original setting screen from URL when LogiCoredata is used.
          toLogiCoredata(to, next, '/settings/user');
        },
      },
      {
        path: 'oauth',
        name: 'GeneralSettingsOauth',
        component: require('src/views/Dashboard/Settings/Oauth').default,
      },
      {
        path: 'oauth/callback',
        name: 'GeneralSettingsOauthCallback',
        component: require('src/views/Dashboard/Settings/Oauth/Callback').default,
      },
      {
        path: 'oauth/:oauthApplicationId',
        name: 'GeneralSettingsOauthDetail',
        component: require('src/views/Dashboard/Settings/Oauth/Detail').default,
      },
    ],
  },
  {
    path: 'super-maintenance',
    component: DashboardLayout,
    children: [
      {
        path: '',
        name: 'SuperMaintenance',
        component: require('src/views/Dashboard/SuperMaintenance.vue').default,
      },
    ],
  },
  {
    path: 'to-logi-coredata',
    name: 'ToLogiCoredata',
    component: require('src/ToLogiCoredata').default,
  },
];

const toLogiCoredata = (to: Route, next: NavigationGuardNext, path: string) => {
  if (useLogiCoredata()) {
    next({ path: 'to-logi-coredata', query: { path: path } });
  } else {
    next();
  }
};

export default routes;
