import type { TimeInteger } from './common';
import type { ProgressPlanSubWorkplacePerformanceBoardList1Response } from './api/progressPlanResponse';

export type ProgressPlanData = {
  startTime: TimeInteger;
  endTime: TimeInteger;
  quantity: number;
  manHours: number;
};

export type ProgressPlanDataWithTotalQuantity = ProgressPlanData & {
  totalQuantity: number;
};

export type ProgressPlan = {
  id: number;
  timetableMasterId: number;
  dt: Date;
  data: ProgressPlanData[];
};

export function convertProgressPlanSubWorkplacePerformanceBoardList1Response(
  response: ProgressPlanSubWorkplacePerformanceBoardList1Response,
): ProgressPlan[] {
  return response.map((progressPlanResponse) => ({
    id: progressPlanResponse.id,
    timetableMasterId: progressPlanResponse.timetable_master_id,
    dt: progressPlanResponse.dt,
    data: progressPlanResponse.data.map((data) => ({
      startTime: data.start_time,
      endTime: data.end_time,
      quantity: data.quantity,
      manHours: data.man_hours,
    })),
  }));
}
