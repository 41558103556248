































import { defineComponent, ref, watch } from '@vue/composition-api';
import { useBudgetGroupPlanBoardMiscs } from '../../composables/useBudgetGroupPlanBoardMiscs';
import { useStaffShiftOverview } from '../../composables/useStaffShiftOverview';
import { useStaffShiftOverviewSummary } from '../../composables/useStaffShiftOverviewSummary';
import { useSearchConditions } from '../../composables/useSearchConditions';
import { StaffShiftOverviewTable } from '../StaffShiftOverviewTable';
import { WarningMessage } from '../WarningMessage';

export default defineComponent({
  components: {
    StaffShiftOverviewTable,
    WarningMessage,
  },
  setup() {
    const { staffShiftOverview } = useStaffShiftOverview();
    const { staffShiftOverviewSummaries } = useStaffShiftOverviewSummary();
    const { budgetGroupPlanBoardMiscs } = useBudgetGroupPlanBoardMiscs();
    const { dates: selectedDates } = useSearchConditions();
    const dates = ref(selectedDates.value);

    watch(staffShiftOverview, () => {
      dates.value = selectedDates.value;
    });

    return {
      staffShiftOverview,
      staffShiftOverviewSummaries,
      budgetGroupPlanBoardMiscs,
      dates,
    };
  },
});
