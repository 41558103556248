










































import { defineComponent, PropType } from '@vue/composition-api';
import { TimetableMasterSelect } from 'src/components/TimetableMasterSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { SubmitButton } from 'src/components/UIComponents/Buttons/SubmitButton';
import { TimetableMaster } from 'src/models/timetableMaster';
import { useWorkPlanUpsertPopover } from '../composables/useWorkPlanUpsertPopover';

export default defineComponent({
  components: {
    PrimaryButton,
    SubmitButton,
    TimetableMasterSelect,
  },
  props: {
    filteredTimetableMasters: {
      type: Array as PropType<TimetableMaster[]>,
      required: true,
    },
    boundariesElement: {
      type: HTMLDivElement,
      required: true,
    },
  },
  setup() {
    return useWorkPlanUpsertPopover();
  },
});
