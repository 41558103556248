import { createInjection } from 'src/util/createInjection';
import { isExist } from 'src/util/isExist';
import { TIME_INTEGER_MAX_VALUE } from 'src/consts';
import { useModal } from 'src/composables/useModal';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useSearchConditions } from './useSearchConditions';
import { useDailySimulation } from './useDailySimulation';
import progressPlanApi from 'src/apis/progressPlan';
import type { BaseProgress, TimetableRow } from '../types';
import type { ProgressPlanBulkUpsertParams } from 'src/models/api/progressPlanRequest';

type InjectionValue = {
  bulkUpsertProgressPlans: (timetableMasterIds: number[]) => Promise<boolean>;
  showsProgressPlanBulkUpsertModal: ReturnType<typeof useModal>['showsModal'];
  showProgressPlanBulkUpsertModal: ReturnType<typeof useModal>['showModal'];
  hideProgressPlanBulkUpsertModal: ReturnType<typeof useModal>['hideModal'];
};

const { provide, inject } = createInjection<InjectionValue>('useProgressPlanBulkUpsertModal');

export function useProgressPlanBulkUpsertModalProvider(): void {
  const {
    showsModal: showsProgressPlanBulkUpsertModal,
    showModal: showProgressPlanBulkUpsertModal,
    hideModal: hideProgressPlanBulkUpsertModal,
  } = useModal();

  const errorBoundary = useErrorBoundary();
  const { workplaceId, budgetGroup, dt } = useSearchConditions();
  const { timetableRowMap } = useDailySimulation();

  const bulkUpsertProgressPlans = errorBoundary.wrap(
    async (timetableMasterIds: number[]) => {
      if (!isExist(budgetGroup.value)) {
        return;
      }
      const progressPlans = timetableMasterIds.map((timetableMasterId) => ({
        timetable_master_id: timetableMasterId,
        data: extractData(timetableRowMap.value[timetableMasterId]),
        dt: dt.value,
      }));
      if (progressPlans.length > 0) {
        await progressPlanApi.bulkUpsert({
          workplace_id: workplaceId,
          budget_group_id: budgetGroup.value.id,
          progress_plans: progressPlans,
        });
      }
      hideProgressPlanBulkUpsertModal();
    },
    {
      fallbackMessage: 'パフォーマンスボードの計画に設定に失敗しました。',
    },
  );

  const extractData = (timetableRow: TimetableRow): ProgressPlanBulkUpsertParams['progress_plans'][0]['data'] => {
    const data = timetableRow.hourBlocks
      .flatMap((hourBlock) => hourBlock.unitTimeBlocks)
      .filter((timeBlock) => timeBlock.progress.isSimulated)
      .map<BaseProgress>((timeBlock) => timeBlock.progress)
      .concat(...timetableRow.actualProgresses)
      .sort((a, b) => a.startTime - b.startTime)
      .map((progress) => {
        const manHours = progress.quantity / progress.productivity;
        return {
          start_time: progress.startTime,
          end_time: progress.endTime,
          quantity: progress.quantity,
          man_hours: isNaN(manHours) ? 0 : manHours,
        };
      });
    // 当日シミュレーションでは実装の都合のためTimeIntegerの最大値を480000として扱っているが
    // アプリケーション全体として最大値は475959であるため保存できる形式に変更する
    if (data.length > 0 && data[data.length - 1].end_time === 480000) {
      data[data.length - 1].end_time = TIME_INTEGER_MAX_VALUE;
    }
    return data;
  };

  provide({
    bulkUpsertProgressPlans,
    showsProgressPlanBulkUpsertModal,
    showProgressPlanBulkUpsertModal,
    hideProgressPlanBulkUpsertModal,
  });
}

export function useProgressPlanBulkUpsertModal(): InjectionValue {
  return inject();
}
