
















































import { computed, defineComponent, ref, type PropType } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { SubmitButton } from 'src/components/UIComponents/Buttons/SubmitButton';
import { createUniqueName } from 'src/util/createUniqueName';
import Form from './Form.vue';

export default defineComponent({
  components: {
    ValidationObserver,
    SubmitButton,
    NormalButton,
    Form,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function as PropType<() => Promise<unknown>>,
      required: true,
    },
    type: {
      type: String as PropType<'save' | 'delete'>,
      default: 'save',
    },
    flexibleWidth: {
      type: Boolean,
      default: false,
    },
    submittable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const formId = createUniqueName('ModalForm');
    const isSubmitting = ref(false);
    const handleSubmit = async () => {
      if (isSubmitting.value) {
        return;
      }
      try {
        isSubmitting.value = true;
        await props.onSubmit();
      } finally {
        isSubmitting.value = false;
      }
    };
    const submitButtonLabel = computed(() => {
      return props.buttonLabel ?? (props.type === 'save' ? '保存' : '削除');
    });

    return {
      formId,
      isSubmitting,
      handleSubmit,
      submitButtonLabel,
    };
  },
});
