import { ref, readonly, type Ref, type DeepReadonly } from '@vue/composition-api';
import timelineApi from 'src/apis/timeline';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { createInjection } from 'src/util/createInjection';
import { useSearchConditions } from './useSearchConditions';
import { isExist } from 'src/util/isExist';
import { ProgressHeader } from 'src/models/progressHeader';
import { useTimetableMasters } from 'src/composables/useTimetableMasters';

type InjectionValue = {
  progressHeaders: DeepReadonly<Ref<ProgressHeader[]>>;
  fetchProgressHeaders: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useProgressHeaders');

export function useProgressHeadersProvider(): void {
  const errorBoundary = useErrorBoundary();
  const progressHeaders = ref<ProgressHeader[]>([]);
  const { timetableMasterMap } = useTimetableMasters();
  const { workplaceId, dt, budgetGroup } = useSearchConditions();

  const fetchProgressHeaders = errorBoundary.wrap(
    async () => {
      if (!isExist(budgetGroup.value)) {
        return;
      }

      const timelineResponse = await timelineApi.timelineIndex({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: dt.value,
      });

      progressHeaders.value = timelineResponse.progress_headers.map((progressHeader) => {
        const timetableMaster = timetableMasterMap.value[progressHeader.timetable_master_id];
        if (!isExist(timetableMaster)) {
          return progressHeader;
        }
        return {
          ...progressHeader,
          timetable_master: timetableMaster,
        };
      });
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  provide({
    progressHeaders: readonly(progressHeaders),
    fetchProgressHeaders,
  });
}

export function useProgressHeaders(): InjectionValue {
  return inject();
}
