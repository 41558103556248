import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { useMouseMove } from 'src/composables/useMouseMove';
import { isExist } from 'src/util/isExist';
import { BLOCK_LENGTH_PER_HOUR, DISPLAY_HOUR_PERIOD, HOUR_BLOCK_WIDTH } from '../consts';
import { hoursToTimeInteger, timeIntegerToHours } from 'src/util/datetime';
import { StaffWorkPlan, StaffWorkPlanRow } from '../types';
import { getMoveLimit, getPositionFromTime } from '../staffWorkPlanHelper';
import { useWorkPlanUpsertPopover } from './useWorkPlanUpsertPopover';
import { useStaffWorkPlan } from './useStaffWorkPlan';

const TIME_BLOCK_WIDTH = HOUR_BLOCK_WIDTH / BLOCK_LENGTH_PER_HOUR;
const HANDLE_MARGIN = 8;

type InjectionValue = {
  onWorkPlanLeftHandleMousedown: (
    workPlan: StaffWorkPlan,
    staffWorkPlanRow: StaffWorkPlanRow,
    e: MouseEvent & { currentTarget: HTMLDivElement; target: HTMLDivElement },
  ) => void;
  onWorkPlanRightHandleMousedown: (
    workPlan: StaffWorkPlan,
    staffWorkPlanRow: StaffWorkPlanRow,
    e: MouseEvent & { currentTarget: HTMLDivElement; target: HTMLDivElement },
  ) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useWorkPlanTransformer');

export function useWorkPlanTransformerProvider(): void {
  const { x } = useMouseMove();
  const { removeWorkPlan } = useStaffWorkPlan();
  const { closePopover } = useWorkPlanUpsertPopover();
  const targetStaffId = ref(-1);
  const targetWorkPlan = ref<StaffWorkPlan | null>(null);
  const targetWorkPlanOriginalStartTime = ref(0);
  const targetWorkPlanOriginalEndTime = ref(0);
  const moveLimit = ref({ min: 0, max: DISPLAY_HOUR_PERIOD });
  const isLeftHandleMoving = ref(false);
  const isRemoveWorkPlan = ref(false);

  const selectedOffsetX = ref<number | null>(null);

  watch(x, (newX) => {
    if (!isExist(selectedOffsetX.value) || !isExist(targetWorkPlan.value)) {
      return;
    }
    const { min: _min, max: _max } = moveLimit.value;
    const min = isLeftHandleMoving.value ? _min : _min - 1;
    const max = isLeftHandleMoving.value ? _max + 1 : _max;
    const margin = isLeftHandleMoving.value ? HANDLE_MARGIN - TIME_BLOCK_WIDTH : HANDLE_MARGIN * 2;
    const selectedTimeBlockNum = Math.max(
      min,
      Math.min(max, Math.floor((newX - selectedOffsetX.value - margin) / TIME_BLOCK_WIDTH)),
    );
    if (isLeftHandleMoving.value) {
      targetWorkPlan.value.startTime = hoursToTimeInteger(
        timeIntegerToHours(targetWorkPlanOriginalStartTime.value) + selectedTimeBlockNum / BLOCK_LENGTH_PER_HOUR,
      );
    } else {
      targetWorkPlan.value.endTime = hoursToTimeInteger(
        timeIntegerToHours(targetWorkPlanOriginalStartTime.value) + (selectedTimeBlockNum + 1) / BLOCK_LENGTH_PER_HOUR,
      );
    }
    isRemoveWorkPlan.value = targetWorkPlan.value.endTime - targetWorkPlan.value.startTime <= 0;
  });

  const onWorkPlanHandleMousedown = (
    staffId: number,
    workPlan: StaffWorkPlan,
    workPlans: StaffWorkPlan[],
    e: MouseEvent & { currentTarget: HTMLDivElement; target: HTMLDivElement },
  ) => {
    closePopover();
    isRemoveWorkPlan.value = false;
    targetStaffId.value = staffId;
    targetWorkPlan.value = workPlan;
    targetWorkPlanOriginalStartTime.value = workPlan.startTime;
    targetWorkPlanOriginalEndTime.value = workPlan.endTime;
    const timeBlockLeft = getPositionFromTime(workPlan.startTime);
    const { x: offsetX } = e.currentTarget.closest('.StaffWorkPlan-plan-table-row')?.getBoundingClientRect() ?? {
      x: 0,
    };
    moveLimit.value = getMoveLimit(workPlan.startTime, workPlans);
    selectedOffsetX.value = timeBlockLeft + offsetX;
  };

  const onWorkPlanLeftHandleMousedown = (
    workPlan: StaffWorkPlan,
    { staffId, workPlans }: StaffWorkPlanRow,
    e: MouseEvent & { currentTarget: HTMLDivElement; target: HTMLDivElement },
  ) => {
    isLeftHandleMoving.value = true;
    onWorkPlanHandleMousedown(staffId, workPlan, workPlans, e);
  };

  const onWorkPlanRightHandleMousedown = (
    workPlan: StaffWorkPlan,
    { staffId, workPlans }: StaffWorkPlanRow,
    e: MouseEvent & { currentTarget: HTMLDivElement; target: HTMLDivElement },
  ) => {
    isLeftHandleMoving.value = false;
    onWorkPlanHandleMousedown(staffId, workPlan, workPlans, e);
  };

  const mouseUpListener = (e: MouseEvent) => {
    if (!isExist(selectedOffsetX.value)) {
      return;
    }
    selectedOffsetX.value = null;
    if (!isExist(targetWorkPlan.value)) {
      return;
    }
    if (isRemoveWorkPlan.value) {
      removeWorkPlan({
        staffId: targetStaffId.value,
        workPlan: targetWorkPlan.value,
      });
    }
  };

  onMounted(() => {
    window.addEventListener('mouseup', mouseUpListener);
  });

  onUnmounted(() => {
    window.removeEventListener('mouseup', mouseUpListener);
  });

  provide({
    onWorkPlanLeftHandleMousedown,
    onWorkPlanRightHandleMousedown,
  });
}

export function useWorkPlanTransformer(): InjectionValue {
  return inject();
}
