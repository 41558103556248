

































import { defineComponent, computed } from '@vue/composition-api';
import { getQuantityColor } from 'src/util/progressHeaderHelpers';
import { useProgressSummary } from '../../composables/useProgressSummary';
import { formatNumber } from '../../utils/filters';
import HeaderItem from './HeaderItem.vue';

export default defineComponent({
  components: {
    HeaderItem,
  },
  setup() {
    const { displayedProgressEndTime, resultQuantity, plannedQuantity } = useProgressSummary();

    const differenceQuantityColor = computed(() => getQuantityColor(resultQuantity.value, plannedQuantity.value));

    const displayedQuantityDifference = computed(() => {
      if (plannedQuantity.value === null) {
        return {
          sign: null,
          value: '-',
          ratio: '-',
        };
      }
      const valueDifference = resultQuantity.value - plannedQuantity.value;
      const rawRatio =
        plannedQuantity.value !== 0 ? Math.abs((resultQuantity.value / plannedQuantity.value) * 100 - 100) : 0;
      const ratio = Math.round(rawRatio * 10) / 10; // 小数第一位まで丸める
      return {
        sign: valueDifference === 0 ? '±' : valueDifference > 0 ? '+' : '-',
        value: formatNumber(Math.abs(valueDifference)),
        ratio: Math.round(ratio),
      };
    });

    return {
      displayedProgressEndTime,
      differenceQuantityColor,
      displayedQuantityDifference,
    };
  },
});
