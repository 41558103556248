











import { PropType, defineComponent } from '@vue/composition-api';
import { SkillOption, useStaffSkill } from 'src/composables/useStaffSkill';
export default defineComponent({
  props: {
    skill: {
      type: Object as PropType<SkillOption>,
      required: true,
    },
  },
  setup() {
    const { changeCheckedSkill } = useStaffSkill();

    return {
      changeCheckedSkill,
    };
  },
});
