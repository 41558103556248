


























































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { CustomFlags } from 'src/components/Staffs/CustomFlags';
import { GenderSelect } from 'src/components/Staffs/GenderSelect';
import { StaffLabelSelect } from 'src/components/Staffs/StaffLabelSelect';
import keyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import forkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import customFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import customFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import customFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import { MacroOperationMasterSelect } from 'src/components/Workplace/MacroOperationMasterSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import { useDisplayConditions } from '../composables/useDisplayConditions';
import { useMacroOperationMasters } from '../composables/useMacroOperationMasters';
import { useStaffs } from '../composables/useStaffs';
import { StaffLabel } from 'src/models/staffLabel';
import { useStaffSkill } from 'src/composables/useStaffSkill';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import { StaffGender, SKILL_MAP } from 'src/consts';
import { useWorkplaceExtension } from 'src/composables/useWorkplaceExtension';
import { useStaffLabels } from 'src/composables/useStaffLabels';
import { isExist } from 'src/util/isExist';
import { STAFF_GENDER_LABEL_MAP } from '../consts';

export default defineComponent({
  components: {
    CustomFlags,
    GenderSelect,
    StaffLabelSelect,
    MacroOperationMasterSelect,
    PrimaryButton,
    keyPlayerIcon,
    forkliftIcon,
    customFlag1Icon,
    customFlag2Icon,
    customFlag3Icon,
  },
  setup() {
    const isOpened = ref<boolean>(false);
    const { macroOperationMasters } = useMacroOperationMasters();
    const { changeEnabledSkill, skillOptions: skillOptionsTemp } = useStaffSkill();
    const { fetchStaffs } = useStaffs();
    const { staffLabels } = useStaffLabels();
    const { hasCustomSkillName1, hasCustomSkillName2, hasCustomSkillName3 } = useWorkplaceExtension();
    const staffLabelTemp = ref<StaffLabel | null>(null);
    const genderTemp = ref<StaffGender | null>(null);
    const macroOperationMasterTemp = ref<MacroOperationMaster | null>(null);

    const { timetableLabels } = useTimetableLabels();
    const { staffLabel, gender, macroOperationMaster, skillOptions } = useDisplayConditions();

    const displayedFilterNames = computed(() => {
      const filterNames: string[] = [];
      if (isExist(macroOperationMaster.value)) {
        filterNames.push(macroOperationMaster.value.name);
      }
      if (isExist(staffLabel.value)) {
        filterNames.push(staffLabel.value.label_name);
      }
      if (isExist(gender.value)) {
        filterNames.push(STAFF_GENDER_LABEL_MAP[gender.value]);
      }
      if (skillOptions.value.some(({ checked }) => checked)) {
        filterNames.push('');
      }
      if (filterNames.length === 0) {
        return 'なし';
      }

      return filterNames.join(', ');
    });

    watch(macroOperationMasters, () => {
      macroOperationMasterTemp.value = null;
    });

    const openPopover = () => {
      changeEnabledSkill(SKILL_MAP.CUSTOM_1, hasCustomSkillName1.value);
      changeEnabledSkill(SKILL_MAP.CUSTOM_2, hasCustomSkillName2.value);
      changeEnabledSkill(SKILL_MAP.CUSTOM_3, hasCustomSkillName3.value);
      Object.values(skillOptions.value).forEach(({ checked }, i) => {
        skillOptionsTemp.value[i].checked = checked;
      });

      staffLabelTemp.value = staffLabel.value;
      genderTemp.value = gender.value;
      macroOperationMasterTemp.value = macroOperationMaster.value;
      isOpened.value = true;
    };

    const closePopover = () => {
      isOpened.value = false;
    };

    const filterStaff = () => {
      staffLabel.value = staffLabelTemp.value;
      gender.value = genderTemp.value;
      macroOperationMaster.value = macroOperationMasterTemp.value;
      Object.values(skillOptionsTemp.value).forEach(({ checked }, i) => {
        skillOptions.value[i].checked = checked;
      });
      fetchStaffs();
      closePopover();
    };

    const onOutSideClick = (e: MouseEvent & { target: HTMLDivElement }) => {
      if (e.target.classList.contains('StaffFilterPopover-button')) {
        return;
      }
      closePopover();
    };

    return {
      timetableLabels,
      displayedFilterNames,
      macroOperationMasters,
      macroOperationMasterTemp,
      staffLabels,
      staffLabelTemp,
      genderTemp,
      skillOptions,
      isOpened,
      openPopover,
      closePopover,
      filterStaff,
      onOutSideClick,
    };
  },
});
