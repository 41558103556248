













import { defineComponent } from '@vue/composition-api';
import { useProgressSet } from '../../composables/useProgressSet';
import { ProgressHeaderPanelsList, ProgressHeaderPanelsListItem } from '../ProgressHeaderPanelsList';
import { ProgressHeaderPanels } from '../ProgressHeaderPanels';

export default defineComponent({
  components: {
    ProgressHeaderPanelsList,
    ProgressHeaderPanelsListItem,
    ProgressHeaderPanels,
  },
  setup() {
    const { progressHeaders, progressPlansMap } = useProgressSet();

    return {
      progressHeaders,
      progressPlansMap,
    };
  },
});
