









import { defineComponent } from '@vue/composition-api';
import { orDefault } from 'src/util/filters';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { formatTime } from '../../utils/filters';

export default defineComponent({
  filters: {
    orDefault,
    formatTime,
  },
  setup() {
    const { progressHeader } = useProgressHeader();

    return {
      progressHeader,
    };
  },
});
