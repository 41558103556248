import axios from 'axios';
import { StringOrNumber } from 'src/models/common';
import { getApiUrl } from 'src/apis/apiUtils';
import {
  IndexParams,
  UpdateParams,
  CreateParams,
  DeleteParams,
  BulkUpdateParams,
} from 'src/models/api/macroOperationSegmentMasterRequest';
import { MacroOperationSegmentMasterResponse } from 'src/models/api/macroOperationSegmentMasterResponse';
import {
  MacroOperationSegmentMaster,
  convertMacroOperationSegmentResponse,
} from 'src/models/macroOperationSegmentMaster';

function getResourceBaseUrl(
  workplaceId: StringOrNumber,
  budgetGroupId: StringOrNumber,
  macroOperationMasterId: StringOrNumber,
): string {
  return getApiUrl(
    `/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/macro_operation_masters/${macroOperationMasterId}/macro_operation_segment_masters`,
  );
}

export default {
  async index(params: IndexParams): Promise<MacroOperationSegmentMaster[]> {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      macro_operation_master_id: macroOperationMasterId,
      ...paramsRest
    } = params;

    const { data } = await axios.get<MacroOperationSegmentMasterResponse[]>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId, macroOperationMasterId)}`,
      { params: paramsRest },
    );
    return data.map(convertMacroOperationSegmentResponse);
  },
  async update(params: UpdateParams): Promise<MacroOperationSegmentMaster> {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      macro_operation_master_id: macroOperationMasterId,
      macro_operation_segment_master_id: macroOperationSegmentMasterId,
      ...paramsRest
    } = params;

    const { data } = await axios.put<MacroOperationSegmentMaster>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId, macroOperationMasterId)}/${macroOperationSegmentMasterId}`,
      paramsRest,
    );
    return data;
  },
  async create(params: CreateParams): Promise<MacroOperationSegmentMaster> {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      macro_operation_master_id: macroOperationMasterId,
      ...paramsRest
    } = params;

    const { data } = await axios.post<MacroOperationSegmentMaster>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId, macroOperationMasterId)}`,
      paramsRest,
    );

    return data;
  },
  async delete(params: DeleteParams) {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      macro_operation_master_id: macroOperationMasterId,
      macro_operation_segment_master_id: macroOperationSegmentMasterId,
    } = params;

    await axios.delete(
      `${getResourceBaseUrl(workplaceId, budgetGroupId, macroOperationMasterId)}/${macroOperationSegmentMasterId}`,
    );
  },
  async bulkUpdateDispOrder(params: BulkUpdateParams): Promise<MacroOperationSegmentMaster[]> {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      macro_operation_master_id: macroOperationMasterId,
      ...paramsRest
    } = params;

    const { data } = await axios.post<MacroOperationSegmentMaster[]>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId, macroOperationMasterId)}/bulk_update_disp_order`,
      paramsRest,
    );
    return data;
  },
};
