



























import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { SubmitButton } from 'src/components/UIComponents/Buttons/SubmitButton';
import { defineComponent } from '@vue/composition-api';
import { useStaffWorkPlanClearModal } from 'src/views/Dashboard/Workplace/StaffWorkPlan/composables/useStaffWorkPlanClearModal';

export default defineComponent({
  components: {
    NormalButton,
    SubmitButton,
  },
  setup() {
    const { isDisplayedStaffWorkPlanClearModal, clearStaffWorkPlan, closeStaffWorkPlanClearModal } =
      useStaffWorkPlanClearModal();

    return {
      isDisplayedStaffWorkPlanClearModal,
      clearStaffWorkPlan,
      closeStaffWorkPlanClearModal,
    };
  },
});
