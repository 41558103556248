import axios, { type AxiosResponse } from 'axios';
import * as utils from 'src/apis/apiUtils';
import type {
  ProgressPlanSubWorkplacePerformanceBoardList1Params,
  ProgressPlanBulkUpsertParams,
} from 'src/models/api/progressPlanRequest';
import type { ProgressPlanSubWorkplacePerformanceBoardList1Response } from 'src/models/api/progressPlanResponse';

export default {
  bulkUpsert({ workplace_id, budget_group_id, ...data }: ProgressPlanBulkUpsertParams): Promise<AxiosResponse<{}>> {
    return axios.post(
      utils.getApiUrl(`/workplaces/${workplace_id}/budget_groups/${budget_group_id}/progress_plans/bulk_upsert`),
      data,
    );
  },
  subWorkplacePerformanceBoardList1({
    workplace_id,
    ...data
  }: ProgressPlanSubWorkplacePerformanceBoardList1Params): Promise<
    AxiosResponse<ProgressPlanSubWorkplacePerformanceBoardList1Response>
  > {
    return axios.get(
      utils.getApiUrl(`/workplaces/${workplace_id}/progress_plans/sub_workplace_performance_board_list1`),
      {
        params: data,
      },
    );
  },
};
