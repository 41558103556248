import { PropType, computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { isExist } from 'src/util/isExist';
import { isHiddenByDisplayNone } from 'src/util/isHiddenByDisplayNone';
import { toNumberOrNull } from 'src/util/numbers';

export default defineComponent({
  name: 'monthly-board-number-input',
  props: {
    value: {
      type: Number,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    tabIndexBase: {
      type: Number,
      default: 0,
    },
    columnCount: {
      type: Number,
      default: 0,
    },
    rowCount: {
      type: Number,
      default: 0,
    },
    onInput: {
      type: Function as PropType<(value: string) => string>,
    },
    isInFirstRow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const toString = (value?: number | null) => String(value == null ? '' : value);
    const valueRef = ref<string>(toString(props.value));
    const lastValue = ref<number | undefined>(props.value);
    watch(valueRef, (newValue) => {
      const convertedValue = props.onInput ? props.onInput(newValue) : newValue;
      valueRef.value = convertedValue;

      const numberValue = toNumberOrNull(convertedValue);
      if (props.value === numberValue) {
        return;
      }
      context.emit('input', numberValue);
    });

    watch(
      () => props.value,
      (newValue?: number) => {
        valueRef.value = toString(newValue);
      },
    );

    const input = ref<{ $el: HTMLInputElement } | null>(null);
    const state = reactive({
      isFocus: false,
    });
    const onFocus = () => {
      state.isFocus = true;
      valueRef.value = toString(props.value);
      lastValue.value = props.value;
    };
    const onBlur = () => {
      state.isFocus = false;
      if (props.value !== lastValue.value) {
        context.emit('update');
      }
    };

    /**
     * 現在画面内に表示されている NumberInput 要素の内、自身が何番目かを管理する変数。
     * Enter キーや Tab キー押下時に、入力フォーカスを移動するのに利用する。
     */
    const numberInputIndex = ref(0);
    onMounted(() => {
      const el = input.value?.$el;
      if (!isExist(el)) {
        return;
      }
      // マウント時に現在表示されている NumberInput 要素の内、自身が何番目かを取得する。
      numberInputIndex.value = Array.from(
        document.querySelectorAll<HTMLInputElement>('.NumberInput .NumberInput-input'),
      ).indexOf(el);
    });

    const onKeydown = (event: KeyboardEvent) => {
      if (!['Enter', 'Tab'].includes(event.key)) {
        return;
      }
      event.preventDefault();

      const target = event.target;
      if (!(target instanceof HTMLInputElement)) {
        return;
      }

      const moveOffsetUnit = (event.key === 'Enter' ? props.columnCount : 1) * (event.shiftKey ? -1 : 1);
      const currentColumnNumber = props.tabIndex % props.columnCount || props.columnCount;
      let moveCount = 1;
      let nextElement: HTMLElement | null = null;
      let tabIndex = props.tabIndex;

      while (moveCount < 200) {
        if (props.isInFirstRow && event.key === 'Enter' && event.shiftKey) {
          const targetColumnNumber = currentColumnNumber - 1;
          tabIndex = targetColumnNumber + props.columnCount * props.rowCount;
        }
        const nextTabindex = tabIndex + moveOffsetUnit * moveCount;
        nextElement = window.document.querySelector(`[tabindex="${nextTabindex}"]`);

        if (nextElement && !isHiddenByDisplayNone(nextElement)) {
          break;
        }
        moveCount++;
      }

      if (!isExist(nextElement) || isHiddenByDisplayNone(nextElement)) {
        if (event.key === 'Enter') {
          const targetColumnNumber = currentColumnNumber + 1;
          const targetRowNumber = 2;
          const nextColumnStartTabIndex = (targetRowNumber - 1) * props.columnCount + targetColumnNumber;

          nextElement = window.document.querySelector(`[tabindex="${nextColumnStartTabIndex}"]`);

          // 予定タブには「出勤実績」欄がない分、隣列の２行目にnextElementを設定するには横１行分のデータ個数を加算する必要がある。
          if (!nextElement) {
            nextElement = window.document.querySelector(`[tabindex="${nextColumnStartTabIndex + props.columnCount}"]`);
          }
        }
        if (event.key === 'Tab') {
          target.blur();
          return;
        }
      }

      if (nextElement instanceof HTMLInputElement || nextElement instanceof HTMLTextAreaElement) {
        nextElement.select();
        nextElement.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }
    };

    return {
      valueRef,
      input,
      state,
      onFocus,
      onBlur,
      onKeydown,
    };
  },
});
