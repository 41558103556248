

















import { defineComponent, computed } from '@vue/composition-api';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { formatFixedDecimal } from '../../utils/filters';
import HeaderItem from './HeaderItem.vue';

export default defineComponent({
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader();

    const displayedManHours = computed(() => ({
      result:
        progressHeader.value.result_man_hours !== null
          ? formatFixedDecimal(progressHeader.value.result_man_hours)
          : '-',
      scheduled:
        progressHeader.value.scheduled_man_hours !== null
          ? formatFixedDecimal(progressHeader.value.scheduled_man_hours)
          : '-',
    }));

    return {
      displayedManHours,
    };
  },
});
