















































import { defineComponent, computed } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { TimetableLabelSelect } from 'src/components/TimetableLabelSelect';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import { useCreateProgressHeaders } from '../../composables/useCreateProgressHeaders';
import { useProgressSet } from '../../composables/useProgressSet';
import { useSearchConditions } from '../../composables/useSearchConditions';
import { useTimetableMasters } from '../../composables/useTimetableMasters';
import { TimetableMasterSelect } from '../TimetableMasterSelect';

export default defineComponent({
  components: {
    BudgetGroupSelect,
    NormalButton,
    PrimaryButton,
    TimetableLabelSelect,
    TimetableMasterSelect,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups();
    const { timetableLabels } = useTimetableLabels();
    const { timetableMasters } = useTimetableMasters();
    const {
      dt,
      budgetGroup,
      timetableLabels: selectedTimetableLabels,
      timetableMasters: selectedTimetableMasters,
    } = useSearchConditions();
    const { canSearch, fetchProgresses } = useProgressSet();
    const { createProgressHeaders } = useCreateProgressHeaders();
    const filteredTimetableMasters = computed(() =>
      timetableMasters.value.filter((v) => v.budget_group_id === (budgetGroup.value?.id ?? v.budget_group_id)),
    );

    return {
      budgetGroups,
      timetableLabels,
      filteredTimetableMasters,
      dt,
      budgetGroup,
      selectedTimetableLabels,
      selectedTimetableMasters,
      canSearch,
      fetchProgresses,
      createProgressHeaders,
    };
  },
});
