



































































































































































import { defineComponent, ref, onMounted, type PropType, computed } from '@vue/composition-api';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { timeInteger } from 'src/filters/datetime_filters';
import { type BudgetGroupPlanBoardMisc } from 'src/models/budgetGroupPlanBoardMisc';
import { type StaffShiftOverview } from 'src/models/staffShiftOverview';
import { type StaffShiftOverviewSummary } from 'src/models/staffShiftOverviewSummary';
import HeadcountBlockContainer from './HeadcountBlockContainer.vue';
import HeadcountBlock from './HeadcountBlock.vue';
import BudgetGroupPlanBoardMiscMemo from './BudgetGroupPlanBoardMiscMemo.vue';
import { isExist } from 'src/util/isExist';
import { useStaffShiftOverviewSummary } from '../../composables/useStaffShiftOverviewSummary';

export default defineComponent({
  components: {
    HeadcountBlockContainer,
    HeadcountBlock,
    BudgetGroupPlanBoardMiscMemo,
  },
  filters: {
    formatTime: (v: number) => timeInteger(v, 'HH : mm'),
    formatDate: (v: Date) => format(v, 'MM / dd'),
    formatWeekDay: (v: Date) => format(v, 'E', { locale: ja }),
  },
  props: {
    dates: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    staffShiftOverview: {
      type: Object as PropType<StaffShiftOverview>,
      required: true,
    },
    staffShiftOverviewSummaries: {
      type: Array as PropType<StaffShiftOverviewSummary[]>,
      required: true,
    },
    budgetGroupPlanBoardMiscs: {
      type: Array as PropType<BudgetGroupPlanBoardMisc[]>,
      required: true,
    },
  },
  setup(props) {
    const { isVisibleStaffShiftOverviewSummary } = useStaffShiftOverviewSummary();
    const tableContainer = ref<HTMLDivElement | null>(null);
    const hourlySlots = [...Array(48)].map((_, i) => i * 10000);

    const summariesMap = computed(() => {
      const currentSummariesMap = props.staffShiftOverviewSummaries.reduce<Record<string, StaffShiftOverviewSummary>>(
        (acc, summary) => {
          const dateKey = summary.dt.toISOString();
          acc[dateKey] = summary;
          return acc;
        },
        {},
      );
      return props.dates.reduce<Record<string, StaffShiftOverviewSummary>>((acc, date) => {
        const dateKey = date.toISOString();
        if (isExist(acc[dateKey])) {
          return acc;
        }
        acc[dateKey] = {
          dt: date,
          scheduled_attendance_count: 0,
          actual_attendance_count: 0,
          late_count: 0,
          early_leave_count: 0,
          absent_count: 0,
          unregistered_count: 0,
          no_break_count: 0,
          over_two_hours_break_count: 0,
        };
        return acc;
      }, currentSummariesMap);
    });

    onMounted(() => {
      if (tableContainer.value === null) {
        return;
      }

      const slot0 = tableContainer.value.querySelector<HTMLElement>('.StaffShiftOverviewTable-time[data-slot="0"]');
      const slot8 = tableContainer.value.querySelector<HTMLElement>('.StaffShiftOverviewTable-time[data-slot="80000"]');

      if (slot0 === null || slot8 === null) {
        return;
      }

      tableContainer.value.scrollLeft = slot8.offsetLeft - slot0.offsetLeft;
    });

    return {
      tableContainer,
      hourlySlots,
      summariesMap,
      isVisibleStaffShiftOverviewSummary,
    };
  },
});
