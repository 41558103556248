



















import { defineComponent, toRef, type PropType } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import { type ProgressPlan } from 'src/models/progressPlan';
import { useProgressHeaderProvider } from '../../composables/useProgressHeader';
import { useProgressPlanProvider } from '../../composables/useProgressPlan';
import { useProgressSummaryProvider } from '../../composables/useProgressSummary';
import { TimetableNamePanel } from '../TimetableNamePanel';
import { HeaderPanel } from '../HeaderPanel';
import { GraphPanel } from '../GraphPanel';
import { QuickRegisterPanel } from '../QuickRegisterPanel';
import { ActualCompletionTimeRegisterPanel } from '../ActualCompletionTimeRegisterPanel';

export default defineComponent({
  components: {
    TimetableNamePanel,
    HeaderPanel,
    GraphPanel,
    QuickRegisterPanel,
    ActualCompletionTimeRegisterPanel,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
    progressPlan: {
      type: Object as PropType<ProgressPlan | null>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    useProgressHeaderProvider({
      progressHeader: toRef(props, 'progressHeader'),
    });
    useProgressPlanProvider({
      progressPlan: toRef(props, 'progressPlan'),
    });
    useProgressSummaryProvider();
  },
});
