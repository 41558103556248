import { DeepReadonly, ref, type Ref } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { useNotification } from 'src/composables/useNotification';
import { StaffWorkPlan } from '../types';
import { useStaffWorkPlan } from './useStaffWorkPlan';

type InjectionValue = {
  isDisplayedStaffWorkPlanCopyPasteModal: Ref<boolean>;
  openStaffWorkPlanCopyPasteModal: () => void;
  closeStaffWorkPlanCopyPasteModal: () => void;
  handleCopyPaste: (copySourceStaff: DeepReadonly<StaffWorkPlan[]>) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffWorkPlanCopyPasteModal');

export function useStaffWorkPlanCopyPasteModalProvider(): void {
  const { notifyError, notifySuccess } = useNotification();
  const { applyCopiedWorkPlans } = useStaffWorkPlan();
  const isDisplayedStaffWorkPlanCopyPasteModal = ref(false);

  const openStaffWorkPlanCopyPasteModal = () => {
    isDisplayedStaffWorkPlanCopyPasteModal.value = true;
  };

  const closeStaffWorkPlanCopyPasteModal = () => {
    isDisplayedStaffWorkPlanCopyPasteModal.value = false;
  };

  const handleCopyPaste = (copySourceWorkPlans: DeepReadonly<StaffWorkPlan[]>) => {
    try {
      applyCopiedWorkPlans(copySourceWorkPlans);
      closeStaffWorkPlanCopyPasteModal();
      notifySuccess('チェックしたスタッフに、コピー元のスタッフの作業工程を割り付けました。');
    } catch (err) {
      notifyError('スタッフへの割り付けに失敗しました', { cause: err });
    }
  };

  provide({
    isDisplayedStaffWorkPlanCopyPasteModal,
    openStaffWorkPlanCopyPasteModal,
    closeStaffWorkPlanCopyPasteModal,
    handleCopyPaste,
  });
}

export function useStaffWorkPlanCopyPasteModal(): InjectionValue {
  return inject();
}
