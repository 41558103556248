




































import { defineComponent, computed } from '@vue/composition-api';
import { getQuantityColor } from 'src/util/progressHeaderHelpers';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { useProgressSummary } from '../../composables/useProgressSummary';
import { formatNumber } from '../../utils/filters';
import HeaderItem from './HeaderItem.vue';

export default defineComponent({
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader();
    const { displayedProgressEndTime, resultQuantity, plannedQuantity } = useProgressSummary();
    const resultQuantityColor = computed(() => getQuantityColor(resultQuantity.value, plannedQuantity.value));

    const displayedQuantities = computed(() => ({
      result: formatNumber(resultQuantity.value),
      planned: plannedQuantity.value !== null ? formatNumber(plannedQuantity.value) : '-',
      scheduled:
        progressHeader.value.scheduled_quantity !== null ? formatNumber(progressHeader.value.scheduled_quantity) : '-',
    }));

    return {
      progressHeader,
      resultQuantityColor,
      displayedProgressEndTime,
      resultQuantity,
      displayedQuantities,
    };
  },
});
