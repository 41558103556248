










































import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { SubmitButton } from 'src/components/UIComponents/Buttons/SubmitButton';
import { DeepReadonly, defineComponent, ref, toRef, watch } from '@vue/composition-api';
import { useStaffWorkPlanCopyPasteModal } from 'src/views/Dashboard/Workplace/StaffWorkPlan/composables/useStaffWorkPlanCopyPasteModal';
import { useStaffWorkPlan } from '../../composables/useStaffWorkPlan';
import { StaffWorkPlanRow } from '../../types';
import { isExist } from 'src/util/isExist';

export default defineComponent({
  components: {
    NormalButton,
    SubmitButton,
  },
  setup() {
    const { state } = useStaffWorkPlan();
    const staffWorkPlans = toRef(state, 'staffWorkPlans');
    const copySourceStaff = ref<DeepReadonly<StaffWorkPlanRow>>();
    const { isDisplayedStaffWorkPlanCopyPasteModal, handleCopyPaste, closeStaffWorkPlanCopyPasteModal } =
      useStaffWorkPlanCopyPasteModal();

    watch(
      () => staffWorkPlans.value,
      () => {
        copySourceStaff.value = staffWorkPlans.value[0];
      },
    );

    const onSubmit = () => {
      if (!isExist(copySourceStaff.value)) {
        return;
      }
      handleCopyPaste(copySourceStaff.value.workPlans);
    };

    return {
      staffWorkPlans,
      copySourceStaff,
      isDisplayedStaffWorkPlanCopyPasteModal,
      onSubmit,
      closeStaffWorkPlanCopyPasteModal,
    };
  },
});
