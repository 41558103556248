import { render, staticRenderFns } from "./StaffShiftOverviewTable.vue?vue&type=template&id=31d0601c&scoped=true"
import script from "./StaffShiftOverviewTable.vue?vue&type=script&lang=ts"
export * from "./StaffShiftOverviewTable.vue?vue&type=script&lang=ts"
import style0 from "./StaffShiftOverviewTable.vue?vue&type=style&index=0&id=31d0601c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d0601c",
  null
  
)

export default component.exports