import { onMounted, onUnmounted, ref, type Ref } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  x: Ref<number>;
  y: Ref<number>;
};

const { provide, inject } = createInjection<InjectionValue>('useMouseMove');

export function useMouseMoveProvider(): void {
  const x = ref<number>(0);
  const y = ref<number>(0);

  const mouseMoveListener = (event: MouseEvent) => {
    x.value = event.clientX;
    y.value = event.clientY;
  };

  onMounted(() => {
    window.addEventListener('mousemove', mouseMoveListener);
  });

  onUnmounted(() => {
    window.removeEventListener('mousemove', mouseMoveListener);
  });

  provide({
    x,
    y,
  });
}

export function useMouseMove(): InjectionValue {
  return inject();
}
