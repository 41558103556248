import { TimeInteger } from 'src/models/common';
import { timeDifferenceInSeconds, timeIntegerToHours } from 'src/util/datetime';
import { BLOCK_LENGTH_PER_HOUR, DISPLAY_HOUR_PERIOD, HOUR_BLOCK_WIDTH } from './consts';
import { StaffWorkPlan } from './types';

export const getPositionFromTime = (time: TimeInteger) => {
  return timeIntegerToHours(time) * HOUR_BLOCK_WIDTH;
};

export const getMoveLimit = (baseTime: TimeInteger, workPlans: StaffWorkPlan[]) => {
  const baseBlockNum = Math.round(timeIntegerToHours(baseTime) * BLOCK_LENGTH_PER_HOUR);
  return workPlans.reduce(
    ({ min, max }, { startTime, endTime }) => {
      const nextMin = Math.round(timeDifferenceInSeconds(baseTime, endTime) / (3600 / BLOCK_LENGTH_PER_HOUR));
      const nextMax = Math.round(timeDifferenceInSeconds(baseTime, startTime) / (3600 / BLOCK_LENGTH_PER_HOUR)) - 1;
      return {
        min: nextMin <= 0 ? nextMin : min,
        max: nextMax >= 0 ? Math.min(nextMax, max) : max,
      };
    },
    { min: -baseBlockNum, max: DISPLAY_HOUR_PERIOD * BLOCK_LENGTH_PER_HOUR - baseBlockNum - 1 },
  );
};
