import {
  type ProgressHeaderPerformanceBoardDetailIndexResponse,
  type ProgressHeaderPerformanceBoardDetailShowResponse,
  type ProgressHeaderPerformanceBoardListIndexResponse,
} from 'src/models/api/progressHeaderResponse';
import { type ProgressHeaderResponse } from './api/timelineResponse';
import { convertTimetableMasterResponse, type TimetableMaster } from 'src/models/timetableMaster';
import { parseYmdDate } from 'src/util/datetime';

type HeadcountByLabel = {
  id: number | null;
  name: string;
  count: number;
  color: string;
};

export type ProgressHeadcountDetail = {
  id: number;
  progress_header_id: number;
  start_time: number;
  end_time: number;
  headcount: number;
  headcount_by_label: HeadcountByLabel[];
};

export type ProgressDetail = {
  id: number;
  progress_header_id: number;
  start_time: number;
  end_time: number;
  quantity: number;
  man_hours: number;
  productivity: number | null;
  velocity: number;
};

export type ProgressHeader = {
  id: number;
  workplace_id: number;
  budget_group_id: number;
  timetable_master_id: number;
  dt: Date;
  start_time: number | null;
  end_time: number | null;
  forecasted_completion_time: number | null;
  target_completion_time: number | null;
  actual_completion_time: number | null;
  forecasted_remaining_quantity: number | null;
  scheduled_quantity: number | null;
  result_quantity: number | null;
  scheduled_man_hours: number | null;
  result_man_hours: number | null;
  latest_productivity: number | null;
  total_productivity: number | null;
  target_productivity: number | null;
  timetable_master: TimetableMaster;
  progress_headcount_details: ProgressHeadcountDetail[];
  progress_details: ProgressDetail[];
  memo: string;
  latest_progress_headcount_detail: ProgressHeadcountDetail | null;
};

export function convertProgressHeaderResponse(response: ProgressHeaderResponse): ProgressHeader {
  const ret: Omit<ProgressHeader, 'timetable_master'> = {
    ...response,
    dt: parseYmdDate(response.dt),
    latest_progress_headcount_detail: null,
  };
  return {
    ...ret,
    // FIXME:
    //  タイムライン画面でもtimetable_masterを一緒に取得すればいい気がする（現在は別途、工程マスタのリストを取得している）。
    //  もし別途取得する必要があれば、型を考える。
    // @ts-expect-error
    timetable_master: null,
  };
}

export function convertProgressHeaderPerformanceBoardDetailIndexResponse(
  response: ProgressHeaderPerformanceBoardDetailIndexResponse,
): ProgressHeader[] {
  return response.map((progressHeader) => ({
    ...progressHeader,
    dt: parseYmdDate(progressHeader.dt),
    timetable_master: convertTimetableMasterResponse(progressHeader.timetable_master),
    progress_headcount_details: [],
  }));
}

export function convertProgressHeaderPerformanceBoardDetailShowResponse(
  response: ProgressHeaderPerformanceBoardDetailShowResponse,
): ProgressHeader {
  return {
    ...response,
    dt: parseYmdDate(response.dt),
    timetable_master: convertTimetableMasterResponse(response.timetable_master),
    progress_headcount_details: [],
  };
}

export function convertProgressHeaderPerformanceBoardListIndexResponse(
  response: ProgressHeaderPerformanceBoardListIndexResponse,
): ProgressHeader[] {
  return response.map((progressHeader) => ({
    ...progressHeader,
    dt: parseYmdDate(progressHeader.dt),
    timetable_master: convertTimetableMasterResponse(progressHeader.timetable_master),
    progress_details: [],
    progress_headcount_details: [],
  }));
}
