import { computed, ComputedRef, reactive, ref, watch, type Ref } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { useMouseMove } from 'src/composables/useMouseMove';
import { isExist } from 'src/util/isExist';
import { BLOCK_LENGTH_PER_HOUR, HOUR_BLOCK_WIDTH, STAFF_WORK_PLAN_TABLE_ROW_HEIGHT } from '../consts';
import { TimetableMaster } from 'src/models/timetableMaster';
import { hoursToTimeInteger, timeIntegerAdd } from 'src/util/datetime';
import { useStaffWorkPlan } from './useStaffWorkPlan';
import { StaffWorkPlan, StaffWorkPlanRow } from '../types';
import { getPositionFromTime } from '../staffWorkPlanHelper';
import { useTimetableMasters } from 'src/composables/useTimetableMasters';
import { TimeInteger } from 'src/models/common';

const TIME_BLOCK_WIDTH = HOUR_BLOCK_WIDTH / BLOCK_LENGTH_PER_HOUR;

type FocusPosition = {
  startTime: TimeInteger;
  endTime: TimeInteger;
};

type InjectionValue = {
  targetStaff: ComputedRef<StaffWorkPlanRow | undefined>;
  selectedTimetableMaster: Ref<TimetableMaster | ''>;
  focusPositionStyle: {
    display: string;
    left: number;
    width: number;
  };
  isBulkUpdateMode: Ref<boolean>;
  isOpened: Ref<boolean>;
  isUpdate: Ref<boolean>;
  createWorkPlan: () => void;
  openPopover: () => void;
  closePopover: () => void;
  onOutSideClick: (e: MouseEvent & { target: HTMLDivElement }) => void;
  onBlankMouseDown: (
    staffId: number,
    e: MouseEvent & { currentTarget: HTMLDivElement; target: HTMLDivElement },
  ) => void;
  onWorkPlanClick: (staffId: number, workPlan: StaffWorkPlan) => void;
  onRemoveButtonClick: () => void;
};

const { provide, inject } = createInjection<InjectionValue>('useWorkPlanUpsertPopover');

export function useWorkPlanUpsertPopoverProvider(): void {
  const { x } = useMouseMove();
  const { addWorkPlan, removeWorkPlan, filteredStaffWorkPlans } = useStaffWorkPlan();
  const isOpened = ref<boolean>(false);
  const isUpdate = ref<boolean>(false);
  const isBulkUpdateMode = ref<boolean>(false);
  const targetStaffId = ref(-1);
  const targetStaff = computed(() =>
    filteredStaffWorkPlans.value.find(({ staffId: _staffId }) => _staffId === targetStaffId.value),
  );
  const targetWorkPlan = ref<StaffWorkPlan | null>(null);
  const selectedTimetableMaster = ref<TimetableMaster | ''>('');
  const { timetableMasterMap } = useTimetableMasters();

  const focusPosition: FocusPosition = reactive({
    startTime: 0,
    endTime: 0,
  });
  const focusPositionStyle = reactive({
    display: 'none',
    left: 0,
    width: 0,
  });
  const focusPositionOffsetX = ref<number | null>(null);
  const focusPositionLeft = ref<number | null>(null);

  const createWorkPlan = () => {
    const timetableMaster = selectedTimetableMaster.value;
    if (typeof timetableMaster === 'string') {
      closePopover();
      return;
    }
    if (isExist(targetWorkPlan.value)) {
      removeWorkPlan({
        staffId: targetStaffId.value,
        workPlan: targetWorkPlan.value,
      });
    }
    const staffsToUpdate = filteredStaffWorkPlans.value.filter(({ isChecked, staffId }) => {
      if (targetStaffId.value === staffId) {
        return true;
      }
      if (isBulkUpdateMode.value && isChecked) {
        return true;
      }
      return false;
    });
    staffsToUpdate.forEach(({ staffId, workPlans: currentWorkPlans }) => {
      adjustWorkPlans(focusPosition, staffId, currentWorkPlans);
      const newWorkPlan = {
        timetableMasterId: timetableMaster.id,
        name: timetableMaster.name,
        backgroundColor: timetableMaster.disp_color,
        startTime: focusPosition.startTime,
        endTime: focusPosition.endTime,
      };
      addWorkPlan({
        staffId: staffId,
        workPlan: newWorkPlan,
      });
    });
    closePopover();
  };

  const adjustWorkPlans = (
    { startTime, endTime }: FocusPosition,
    targetStaffId: number,
    currentWorkPlans: StaffWorkPlan[],
  ) => {
    currentWorkPlans.forEach((currentWorkPlan) => {
      // 重ならない場合: 何もしない
      if (currentWorkPlan.endTime <= startTime || endTime <= currentWorkPlan.startTime) {
        return;
      }
      // 既存の工程を内包する場合: 既存の工程を削除する
      if (startTime <= currentWorkPlan.startTime && currentWorkPlan.endTime <= endTime) {
        removeWorkPlan({
          staffId: targetStaffId,
          workPlan: currentWorkPlan,
        });
        return;
      }
      // 既存の工程に内包される場合: 既存の工程で挟む形になるように、既存の WorkPlan の終了時間を変更しつつ、新しい workPlan の後に workPlan を足す
      if (currentWorkPlan.startTime < startTime && endTime < currentWorkPlan.endTime) {
        const currentWorkPlan2 = {
          timetableMasterId: currentWorkPlan.timetableMasterId,
          name: currentWorkPlan.name,
          backgroundColor: currentWorkPlan.backgroundColor,
          startTime: endTime,
          endTime: currentWorkPlan.endTime,
        };
        currentWorkPlan.endTime = startTime;
        addWorkPlan({
          staffId: targetStaffId,
          workPlan: currentWorkPlan2,
        });
        return;
      }
      // 既存の工程の startTime に重なる場合: 既存の工程の startTime を、新規追加分の終了時間に合わせる
      if (startTime < currentWorkPlan.startTime && currentWorkPlan.startTime < endTime) {
        currentWorkPlan.startTime = endTime;
        return;
      }
      // 既存の工程の endTime に重なる場合: 既存工程の endTime を、新規追加分の開始時間に合わせる
      if (startTime < currentWorkPlan.endTime && currentWorkPlan.endTime < endTime) {
        currentWorkPlan.endTime = startTime;
      }
    });
  };

  const openPopover = (timetableMaster: TimetableMaster | null = null) => {
    isOpened.value = true;
    isUpdate.value = !!timetableMaster;
    selectedTimetableMaster.value = timetableMaster ?? '';
  };

  const closePopover = () => {
    isOpened.value = false;
    focusPositionStyle.display = 'none';
    targetWorkPlan.value = null;
    isBulkUpdateMode.value = false;
  };

  const onOutSideClick = () => {
    if (!isExist(focusPositionOffsetX.value) || !isExist(focusPositionLeft.value)) {
      closePopover();
      return;
    }
    focusPositionOffsetX.value = null;
    focusPositionLeft.value = null;
    openPopover();
  };

  watch(x, (newX) => {
    if (!isExist(focusPositionOffsetX.value) || !isExist(focusPositionLeft.value)) {
      return;
    }
    const selectedTimeBlockNum = Math.floor((newX - focusPositionOffsetX.value) / TIME_BLOCK_WIDTH);
    if (selectedTimeBlockNum >= 0) {
      focusPositionStyle.left = focusPositionLeft.value;
    } else {
      focusPositionStyle.left = focusPositionLeft.value + selectedTimeBlockNum * TIME_BLOCK_WIDTH;
    }
    const width = (Math.abs(selectedTimeBlockNum) + 1) * TIME_BLOCK_WIDTH;
    focusPositionStyle.width = width - ((focusPositionStyle.left + width) % HOUR_BLOCK_WIDTH === 0 ? 1 : 0);
    focusPosition.startTime = hoursToTimeInteger(focusPositionStyle.left / 100);
    focusPosition.endTime = timeIntegerAdd(focusPosition.startTime, hoursToTimeInteger(width / 100));
  });

  const onBlankMouseDown = (
    staffId: number,
    e: MouseEvent & { currentTarget: HTMLDivElement; target: HTMLDivElement },
  ) => {
    closePopover();
    targetStaffId.value = staffId;
    const { x: offsetX } = e.currentTarget.getBoundingClientRect();
    const timeBlockLeft = Math.floor((x.value - offsetX) / TIME_BLOCK_WIDTH) * TIME_BLOCK_WIDTH;
    focusPositionStyle.left = timeBlockLeft;
    focusPositionStyle.width = TIME_BLOCK_WIDTH - ((timeBlockLeft + TIME_BLOCK_WIDTH) % HOUR_BLOCK_WIDTH === 0 ? 1 : 0);
    focusPositionStyle.display = 'block';
    focusPosition.startTime = hoursToTimeInteger(timeBlockLeft / 100);
    focusPosition.endTime = timeIntegerAdd(focusPosition.startTime, hoursToTimeInteger(1 / BLOCK_LENGTH_PER_HOUR));
    focusPositionOffsetX.value = timeBlockLeft + offsetX;
    focusPositionLeft.value = timeBlockLeft;

    isBulkUpdateMode.value = targetStaff.value?.isChecked ?? false;
  };

  const onWorkPlanClick = (staffId: number, workPlan: StaffWorkPlan) => {
    closePopover();
    targetStaffId.value = staffId;
    targetWorkPlan.value = workPlan;
    const timeBlockLeft = getPositionFromTime(workPlan.startTime);
    focusPositionStyle.left = timeBlockLeft;
    focusPositionStyle.width = getPositionFromTime(workPlan.endTime) - timeBlockLeft;
    focusPositionStyle.display = 'block';
    focusPosition.startTime = workPlan.startTime;
    focusPosition.endTime = workPlan.endTime;
    openPopover(timetableMasterMap.value[workPlan.timetableMasterId]);
  };

  const onRemoveButtonClick = () => {
    if (!isExist(targetWorkPlan.value)) {
      return;
    }
    removeWorkPlan({
      staffId: targetStaffId.value,
      workPlan: targetWorkPlan.value,
    });
    closePopover();
  };

  provide({
    targetStaff,
    selectedTimetableMaster,
    focusPositionStyle,
    isOpened,
    isUpdate,
    isBulkUpdateMode,
    createWorkPlan,
    openPopover,
    closePopover,
    onOutSideClick,
    onBlankMouseDown,
    onWorkPlanClick,
    onRemoveButtonClick,
  });
}

export function useWorkPlanUpsertPopover(): InjectionValue {
  return inject();
}
