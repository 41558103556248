






















import { defineComponent, PropType } from '@vue/composition-api';
import { StaffGender, StaffGenderSelectOptions } from 'src/consts';

export default defineComponent({
  props: {
    value: {
      type: Number as PropType<StaffGender | null>,
      default: null,
    },
  },
  setup() {
    return {
      StaffGenderSelectOptions,
    };
  },
});
