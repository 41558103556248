import { ref, type Ref } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { useNotification } from 'src/composables/useNotification';
import { useStaffWorkPlan } from './useStaffWorkPlan';

type InjectionValue = {
  isDisplayedStaffWorkPlanClearModal: Ref<boolean>;
  openStaffWorkPlanClearModal: () => void;
  closeStaffWorkPlanClearModal: () => void;
  clearStaffWorkPlan: () => void;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffWorkPlanClearModal');

export function useStaffWorkPlanClearModalProvider(): void {
  const { notifyError, notifySuccess } = useNotification();
  const { clearWorkPlans } = useStaffWorkPlan();
  const isDisplayedStaffWorkPlanClearModal = ref(false);

  const openStaffWorkPlanClearModal = () => {
    isDisplayedStaffWorkPlanClearModal.value = true;
  };

  const closeStaffWorkPlanClearModal = () => {
    isDisplayedStaffWorkPlanClearModal.value = false;
  };

  const clearStaffWorkPlan = () => {
    try {
      clearWorkPlans();
      closeStaffWorkPlanClearModal();
      notifySuccess('スタッフへの割付をクリアしました');
    } catch (err) {
      notifyError('スタッフへの割付のクリアに失敗しました', { cause: err });
    }
  };

  provide({
    isDisplayedStaffWorkPlanClearModal,
    openStaffWorkPlanClearModal,
    closeStaffWorkPlanClearModal,
    clearStaffWorkPlan,
  });
}

export function useStaffWorkPlanClearModal(): InjectionValue {
  return inject();
}
