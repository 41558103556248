
































import { defineComponent } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useSearchConditions } from '../composables/useSearchConditions';
import { useStaffWorkPlan } from '../composables/useStaffWorkPlan';
import { useStaffs } from '../composables/useStaffs';

export default defineComponent({
  components: {
    BudgetGroupSelect,
    PrimaryButton,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups();
    const { dt, budgetGroup, workplaceId } = useSearchConditions();
    const { state, fetchStaffWorkPlan } = useStaffWorkPlan();
    const { fetchStaffs } = useStaffs();

    const displayWorkPlan = async (): Promise<void> => {
      fetchStaffWorkPlan();
      fetchStaffs();
    };

    return {
      baseDate: dt,
      budgetGroup,
      budgetGroups,
      displayWorkPlan,
      workplaceId,
      state,
    };
  },
});
