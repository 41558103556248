import { computed, ComputedRef } from '@vue/composition-api';
import { useTimetableMasters } from 'src/composables/useTimetableMasters';
import { TimetableMaster } from 'src/models/timetableMaster';
import { createInjection } from 'src/util/createInjection';
import { SUPPORT_MASTER_ID, SUPPORT_DISP_COLOR } from '../consts';

type TimetableMasterForHeadcountTimetable = Pick<
  TimetableMaster,
  'id' | 'timetable_label_id' | 'name' | 'disp_color' | 'disp_order'
>;
export type TimetableMasterMapWithSupport = Record<number, TimetableMasterForHeadcountTimetable>;

type InjectionValue = {
  timetableMastersWithSupport: ComputedRef<TimetableMasterForHeadcountTimetable[]>;
  timetableMasterMapWithSupport: ComputedRef<TimetableMasterMapWithSupport>;
};

const { provide, inject } = createInjection<InjectionValue>('useTimetableMastersWithSupport');

export function useTimetableMastersWithSupportProvider(): void {
  const { timetableMasters } = useTimetableMasters();
  const timetableMastersWithSupport = computed(() =>
    timetableMasters.value
      .map(({ id, timetable_label_id, name, disp_color, disp_order }) => ({
        id,
        timetable_label_id,
        name,
        disp_color,
        disp_order,
      }))
      .concat([
        {
          id: SUPPORT_MASTER_ID,
          timetable_label_id: -1,
          name: '応援',
          disp_color: SUPPORT_DISP_COLOR,
          disp_order: Number.MAX_SAFE_INTEGER,
        },
      ]),
  );

  const timetableMasterMapWithSupport = computed(() =>
    timetableMastersWithSupport.value.reduce<TimetableMasterMapWithSupport>((map, master) => {
      map[master.id] = master;
      return map;
    }, {}),
  );

  provide({
    timetableMastersWithSupport,
    timetableMasterMapWithSupport,
  });
}

export function useTimetableMastersWithSupport(): InjectionValue {
  return inject();
}
