import { parseYmdDate } from 'src/util/datetime';
import { RescueAgencyOrderResponse } from 'src/models/api/rescueAgencyOrderResponse';
import { StaffAgency } from 'src/models/staffAgency';

export interface RescueAgencyOrder {
  id: number;
  workplace_id: number;
  staff_agency_id: number;
  staff_agency?: StaffAgency;
  dt: Date;
  day_block1_num_ordered: number;
  day_block1_num_confirmed: number;
  day_block2_num_ordered: number;
  day_block2_num_confirmed: number;
}

export function convertRescueAgencyOrderResponse(response: RescueAgencyOrderResponse): RescueAgencyOrder {
  return {
    ...response,
    dt: parseYmdDate(response.dt),
    staff_agency: response.staff_agency,
  };
}
