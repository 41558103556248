import { ref, readonly, type Ref, type DeepReadonly } from '@vue/composition-api';

import regularShiftApi from 'src/apis/staffShift';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { createInjection } from 'src/util/createInjection';
import { useSearchConditions } from './useSearchConditions';
import { isExist } from 'src/util/isExist';
import { OrigStaffWithShifts } from 'src/models/regularShift';
import { ATTENDANCE_TYPE, ATTENDANCE_TYPES_AT_WORK, AttendanceType } from 'src/consts';

type InjectionValue = {
  regularShifts: DeepReadonly<Ref<OrigStaffWithShifts[]>>;
  fetchRegularShifts: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useRegularShifts');

export function useRegularShiftsProvider(): void {
  const errorBoundary = useErrorBoundary();
  const regularShifts = ref<OrigStaffWithShifts[]>([]);
  const { workplaceId, dt, budgetGroup } = useSearchConditions();

  const fetchRegularShifts = errorBoundary.wrap(
    async () => {
      if (!isExist(budgetGroup.value)) {
        return;
      }

      const regularShiftsResponse = await regularShiftApi.regularShiftIndex({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        start_date: dt.value,
      });

      regularShifts.value = regularShiftsResponse.shifts.filter((shift) => {
        return (
          shift.is_enabled &&
          ATTENDANCE_TYPES_AT_WORK.includes(shift.shifts[0].data?.scheduled_attendance_type ?? ATTENDANCE_TYPE.TBD)
        );
      });
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  provide({
    regularShifts: readonly(regularShifts),
    fetchRegularShifts: fetchRegularShifts,
  });
}

export function useRegularShifts(): InjectionValue {
  return inject();
}
