










































import { computed, defineComponent, ref } from '@vue/composition-api';
import { TimetableLabelSelect } from 'src/components/TimetableLabelSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import { useDisplayConditions } from '../composables/useDisplayConditions';
import { type TimetableLabel } from 'src/models/timetableLabel';
import { useStaffWorkPlan } from '../composables/useStaffWorkPlan';

export default defineComponent({
  components: {
    PrimaryButton,
    TimetableLabelSelect,
  },
  setup() {
    const isOpened = ref<boolean>(false);
    const selectedTimetableLabelTemp = ref<TimetableLabel | ''>('');
    const { timetableLabels } = useTimetableLabels();
    const { timetableLabel } = useDisplayConditions();
    const { state } = useStaffWorkPlan();

    const filteredTimetableLabels = computed(() =>
      timetableLabels.value.filter(({ id }) => {
        return state.headcountTimetable.map(({ timetableLabelId }) => timetableLabelId).includes(id);
      }),
    );
    const displayedTimetableLabel = computed(() => timetableLabel.value?.name ?? 'なし');

    const openPopover = () => {
      selectedTimetableLabelTemp.value = !timetableLabel.value ? '' : timetableLabel.value;
      isOpened.value = true;
    };

    const closePopover = () => {
      isOpened.value = false;
    };

    const filterTimetable = () => {
      timetableLabel.value =
        typeof selectedTimetableLabelTemp.value === 'string' ? null : selectedTimetableLabelTemp.value;
      closePopover();
    };

    const onOutSideClick = (e: MouseEvent & { target: HTMLDivElement }) => {
      if (e.target.classList.contains('TimetableFilterPopover-button')) {
        return;
      }
      closePopover();
    };

    return {
      filteredTimetableLabels,
      selectedTimetableLabelTemp,
      displayedTimetableLabel,
      isOpened,
      openPopover,
      closePopover,
      filterTimetable,
      onOutSideClick,
    };
  },
});
