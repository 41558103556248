

























import { defineComponent, computed } from '@vue/composition-api';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { formatNumber } from '../../utils/filters';
import HeaderItem from './HeaderItem.vue';

export default defineComponent({
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader();
    const displayedHeadcount = computed(() =>
      progressHeader.value.latest_progress_headcount_detail?.headcount !== undefined
        ? formatNumber(progressHeader.value.latest_progress_headcount_detail.headcount)
        : '-',
    );
    const displayedHeadcountsByLabel = computed(() =>
      (progressHeader.value.latest_progress_headcount_detail?.headcount_by_label ?? [])
        .filter((headcountByLabel) => headcountByLabel.count > 0)
        .map((headcountByLabel) => ({ ...headcountByLabel, count: formatNumber(headcountByLabel.count) })),
    );

    return {
      displayedHeadcount,
      displayedHeadcountsByLabel,
    };
  },
});
