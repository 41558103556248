






















import { DeepReadonly, PropType, defineComponent } from '@vue/composition-api';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<MacroOperationMaster | null>,
      default: null,
    },
    macroOperationMasters: {
      type: Array as PropType<DeepReadonly<MacroOperationMaster[]>>,
      required: true,
    },
  },
});
