

















































import { computed, defineComponent } from '@vue/composition-api';
import keyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import forkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import customFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import customFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import customFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import { StaffExperienceMatrixHeaderOperationItem } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/StaffExperienceMatrixHeaderOperationItem';
import { useStaffExperience } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/composables/useStaffExperience';
import { useStaffSkill } from 'src/composables/useStaffSkill';

export default defineComponent({
  components: {
    keyPlayerIcon,
    forkliftIcon,
    customFlag1Icon,
    customFlag2Icon,
    customFlag3Icon,
    StaffExperienceMatrixHeaderOperationItem,
  },
  setup() {
    const { staffsWithTimetableMasterSummaries } = useStaffExperience();
    const { enabledCustomSkill1, enabledCustomSkill2, enabledCustomSkill3 } = useStaffSkill();

    const timetableMasters = computed(() => staffsWithTimetableMasterSummaries.value[0].timetable_master_summaries);

    return {
      enabledCustomSkill1,
      enabledCustomSkill2,
      enabledCustomSkill3,
      timetableMasters,
    };
  },
});
