import { ref, type Ref } from '@vue/composition-api';
import { generateInitialSkillOptions, SkillOption } from 'src/composables/useStaffSkill';
import { StaffGender } from 'src/consts';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import { StaffLabel } from 'src/models/staffLabel';
import { type TimetableLabel } from 'src/models/timetableLabel';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  timetableLabel: Ref<TimetableLabel | null>;
  staffLabel: Ref<StaffLabel | null>;
  gender: Ref<StaffGender | null>;
  macroOperationMaster: Ref<MacroOperationMaster | null>;
  skillOptions: Ref<SkillOption[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useDisplayConditions');

export function useDisplayConditionsProvider(): void {
  const timetableLabel = ref<TimetableLabel | null>(null);
  const staffLabel = ref<StaffLabel | null>(null);
  const gender = ref<StaffGender | null>(null);
  const macroOperationMaster = ref<MacroOperationMaster | null>(null);
  const skillOptions = ref(generateInitialSkillOptions());

  provide({
    timetableLabel,
    staffLabel,
    gender,
    macroOperationMaster,
    skillOptions,
  });
}

export function useDisplayConditions(): InjectionValue {
  return inject();
}
