import { computed, type ComputedRef } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { timeIntegerToSeconds, timeIntegerToTimeString } from 'src/util/datetime';
import type { ProgressPlanDataWithTotalQuantity } from 'src/models/progressPlan';
import { useProgressHeader } from './useProgressHeader';
import { useProgressPlan } from './useProgressPlan';

type InjectionValue = {
  displayedProgressEndTime: ComputedRef<string>;
  progressPlanDataListWithTotalQuantity: ComputedRef<ProgressPlanDataWithTotalQuantity[]>;
  resultQuantity: ComputedRef<number>;
  plannedQuantity: ComputedRef<number | null>;
  requiredProductivity: ComputedRef<number | null>;
  plannedProductivity: ComputedRef<number | null>;
};

const { provide, inject } = createInjection<InjectionValue>('useProgress');

export const useProgressSummaryProvider = () => {
  const { progressHeader } = useProgressHeader();
  const { progressPlan } = useProgressPlan();

  const displayedProgressEndTime = computed(() => {
    return progressHeader.value.end_time ? timeIntegerToTimeString(progressHeader.value.end_time) : '--:--';
  });

  const progressPlanDataListWithTotalQuantity = computed<ProgressPlanDataWithTotalQuantity[]>(() => {
    const progressPlanValue = progressPlan.value;
    if (progressPlanValue === null) {
      return [];
    }
    let previousQuantity = 0;
    return progressPlanValue.data.map<ProgressPlanDataWithTotalQuantity>((data, index) => {
      if (index !== 0) {
        const previousData = progressPlanValue.data[index - 1];
        previousQuantity += previousData.quantity;
      }
      return {
        ...data,
        totalQuantity: data.quantity + previousQuantity,
      };
    });
  });

  const targetData = computed<ProgressPlanDataWithTotalQuantity | null>(() => {
    const endTime = progressHeader.value.end_time;
    if (endTime === null) {
      return null;
    }
    return (
      progressPlanDataListWithTotalQuantity.value.find(
        (data) => data.startTime <= endTime && endTime <= data.endTime,
      ) ?? null
    );
  });

  const resultQuantity = computed(() => {
    return progressHeader.value.result_quantity ?? 0;
  });

  const plannedQuantity = computed<number | null>(() => {
    const endTime = progressHeader.value.end_time;
    if (endTime === null || targetData.value === null) {
      return null;
    }
    const durationRatio =
      (timeIntegerToSeconds(targetData.value.endTime) - timeIntegerToSeconds(endTime)) /
      (timeIntegerToSeconds(targetData.value.endTime) - timeIntegerToSeconds(targetData.value.startTime));
    return targetData.value.totalQuantity - targetData.value.quantity * durationRatio;
  });

  const requiredProductivity = computed(() => {
    const scheduledQuantity = progressHeader.value.scheduled_quantity;
    const resultQuantity = progressHeader.value.result_quantity ?? 0;
    const scheduledManHours = progressHeader.value.scheduled_man_hours;
    const resultManHours = progressHeader.value.result_man_hours ?? 0;
    if (
      scheduledQuantity === null ||
      scheduledManHours === null ||
      scheduledManHours - resultManHours === 0 ||
      resultQuantity >= scheduledQuantity
    ) {
      return null;
    }
    return (scheduledQuantity - resultQuantity) / (scheduledManHours - resultManHours);
  });

  const plannedProductivity = computed<number | null>(() => {
    if (targetData.value === null || targetData.value.manHours === 0) {
      return null;
    }
    return targetData.value.quantity / targetData.value.manHours;
  });

  provide({
    displayedProgressEndTime,
    progressPlanDataListWithTotalQuantity,
    resultQuantity,
    plannedQuantity,
    requiredProductivity,
    plannedProductivity,
  });
};

export function useProgressSummary(): InjectionValue {
  return inject();
}
