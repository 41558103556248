














import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api';
import { useStaffWorkPlan } from '../composables/useStaffWorkPlan';
import { STAFF_WORK_PLAN_TABLE_ROW_HEIGHT } from '../consts';
import { useWorkPlanUpsertPopover } from '../composables/useWorkPlanUpsertPopover';
import { StaffWorkPlanRow } from '../types';

export default defineComponent({
  props: {
    staff: {
      type: Object as PropType<StaffWorkPlanRow>,
      required: true,
    },
  },
  setup(props) {
    const { filteredStaffWorkPlans } = useStaffWorkPlan();
    const { focusPositionStyle } = useWorkPlanUpsertPopover();
    const top = computed(
      () =>
        filteredStaffWorkPlans.value.findIndex(({ staffId }) => staffId === props.staff.staffId) *
        STAFF_WORK_PLAN_TABLE_ROW_HEIGHT,
    );

    return {
      top,
      ...toRefs(focusPositionStyle),
    };
  },
});
