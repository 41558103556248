import { ref, type Ref } from '@vue/composition-api';
import staffShiftApi from 'src/apis/staffShift';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type MacroOperationMaster } from 'src/models/macroOperationMaster';
import { type StaffLabel } from 'src/models/staffLabel';
import {
  type StaffShiftOverviewSummary,
  convertStaffShiftOverviewSummaryResponse,
} from 'src/models/staffShiftOverviewSummary';
import { createInjection } from 'src/util/createInjection';

type Params = {
  budgetGroup: BudgetGroup;
  dates: Date[];
  macroOperationMasters: MacroOperationMaster[];
  staffLabels: StaffLabel[];
  isKeyPlayer: boolean;
  isForkman: boolean;
  hasCustomSkill1: boolean;
  hasCustomSkill2: boolean;
  hasCustomSkill3: boolean;
};

type InjectionValue = {
  staffShiftOverviewSummaries: Ref<StaffShiftOverviewSummary[] | null>;
  fetchStaffShiftOverviewSummary: (params: Params) => Promise<boolean>;
  isVisibleStaffShiftOverviewSummary: Ref<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffShiftOverviewSummary');

export function useStaffShiftOverviewSummaryProvider(): void {
  const errorBoundary = useErrorBoundary();
  const staffShiftOverviewSummaries = ref<StaffShiftOverviewSummary[] | null>(null);
  const isVisibleStaffShiftOverviewSummary = ref(true);

  const fetchStaffShiftOverviewSummary = errorBoundary.wrap(
    async (params: Params) => {
      const response = await staffShiftApi.staffShiftOverviewSummary({
        workplace_id: params.budgetGroup.workplace_id,
        budget_group_id: params.budgetGroup.id,
        dates: params.dates,
        macro_operation_master_ids: params.macroOperationMasters.map((v) => v.id),
        staff_label_ids: params.staffLabels.map((v) => v.id),
        is_key_player: params.isKeyPlayer,
        is_forkman: params.isForkman,
        has_custom_skill1: params.hasCustomSkill1,
        has_custom_skill2: params.hasCustomSkill2,
        has_custom_skill3: params.hasCustomSkill3,
      });
      staffShiftOverviewSummaries.value = convertStaffShiftOverviewSummaryResponse(response);
    },
    {
      fallbackMessage: 'シフト特性の取得に失敗しました',
    },
  );

  provide({
    staffShiftOverviewSummaries,
    fetchStaffShiftOverviewSummary,
    isVisibleStaffShiftOverviewSummary,
  });
}

export function useStaffShiftOverviewSummary(): InjectionValue {
  return inject();
}
