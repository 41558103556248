import { render, staticRenderFns } from "./HeaderItem.vue?vue&type=template&id=fc60d7ce&scoped=true"
var script = {}
import style0 from "./HeaderItem.vue?vue&type=style&index=0&id=fc60d7ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc60d7ce",
  null
  
)

export default component.exports