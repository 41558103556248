
import Vue from 'vue';
import { SetupContext, computed, defineComponent, inject, reactive, watch } from '@vue/composition-api';
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal';
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift';
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState';
import { formatTimeInteger } from 'src/util/datetime';
import { addDays, format } from 'date-fns';
import { StaffWithShifts } from 'src/models/regularShift';
import CsvDownloadLargeButton from 'src/components/UIComponents/Buttons/CsvDownloadButton/CsvDownloadLargeButton.vue';
import { CsvLine } from 'src/util/type_util';
import { notifyError1 } from 'src/hooks/notificationHook';

type State = {
  startDate: Date;
  endDate: Date;
  shiftPhase: string;
};

type CsvState = {
  csvFileName: string;
  csvData: CsvLine[];
};

const CHECKED_SHIFT = {
  preShift: 'preShift',
  shift: 'shift',
};

const formatDateToDay = (date: Date) => new Date(format(date, 'yyyy/MM/dd'));

export default defineComponent({
  name: 'shift-csv-output-modal',
  components: {
    CsvDownloadLargeButton,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    const modalState = inject(ModalDisplayStateKey);
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const commonState = inject(CommonStateKey);
    if (!modalState || !staffWithShiftsState || !commonState) {
      throw new Error('State Error');
    }

    const { staffsWithShifts } = staffWithShiftsState;
    const { isDisplayShiftCsvOutputModal, closeShiftCsvOutputModal } = modalState;
    const { baseDate, attendanceTypes } = commonState;
    const baseFormattedDate = computed(() => formatDateToDay(baseDate.value));

    const setDateRange = () => {
      state.startDate = baseFormattedDate.value;
      state.endDate = addDays(baseFormattedDate.value, 9);
    };

    const state = reactive<State>({
      startDate: formatDateToDay(baseDate.value),
      endDate: formatDateToDay(addDays(baseDate.value, 9)),
      shiftPhase: CHECKED_SHIFT.preShift,
    });

    const csvState = reactive<CsvState>({
      csvFileName: '',
      csvData: [],
    });

    const isDateInvalid = computed(() => state.startDate > state.endDate);

    const beforeDownload = () => {
      setCsvFileName();
      setCsvData();
      closeShiftCsvOutputModal();
    };

    const setCsvFileName = () => {
      csvState.csvFileName = `staff_shifts_${format(new Date(), 'yyyyMMddHHmmss')}.csv`;
    };

    const setCsvData = () => {
      const csvHeaders: string[] = [
        '社員番号',
        '姓',
        '名',
        '日付',
        '勤怠',
        '出勤時刻',
        '退勤時刻',
        '休憩予定1 開始時刻',
        '休憩予定1 終了時刻',
        '休憩予定2 開始時刻',
        '休憩予定2 終了時刻',
      ];

      csvState.csvData = [csvHeaders];

      staffsWithShifts.value.forEach((item) => {
        if (!item.should_show) {
          return;
        }
        const shiftsKey = state.shiftPhase === CHECKED_SHIFT.preShift ? 'orig_pre_shifts' : 'orig_shifts';
        const isPreShift = state.shiftPhase === CHECKED_SHIFT.preShift;

        item[shiftsKey].forEach((shift) => {
          if (isWithinDateRange(shift?.date, state.startDate, state.endDate)) {
            csvState.csvData.push(buildCsvLine(item, shift, isPreShift));
          }
        });
      });

      csvState.csvData.sort((a, b) => {
        const dateAString = a[3];
        const dateBString = b[3];

        const dateA = dateAString ? new Date(dateAString) : new Date(Infinity);
        const dateB = dateBString ? new Date(dateBString) : new Date(Infinity);

        // 日付でソート
        if (dateA.getTime() !== dateB.getTime()) {
          return dateA.getTime() - dateB.getTime();
        }
        // `staff_number` でソート
        const staffNumberA = typeof a[0] === 'string' ? a[0] : '';
        const staffNumberB = typeof b[0] === 'string' ? b[0] : '';

        return staffNumberA.localeCompare(staffNumberB);
      });
    };

    const isWithinDateRange = (date: string, startDate: Date, endDate: Date) => {
      const shiftDate = formatDateToDay(new Date(date));
      return shiftDate >= startDate && shiftDate <= endDate;
    };

    const buildCsvLine = (item: StaffWithShifts, shift: any, isPreShift: boolean) => {
      const shiftData = shift?.data || {};
      const shiftDate = format(new Date(shift?.date), 'yyyy/MM/dd');

      return [
        item.staff_number,
        item.family_name,
        item.first_name,
        shiftDate,
        getAttendanceTypeName(isPreShift ? shiftData.attendance_type : shiftData.scheduled_attendance_type),
        formatShiftTime(isPreShift ? shiftData.work_start_time : shiftData.scheduled_work_start_time),
        formatShiftTime(isPreShift ? shiftData.work_end_time : shiftData.scheduled_work_end_time),
        formatShiftTime(isPreShift ? shiftData.break1_start_time : shiftData.scheduled_break1_start_time),
        formatShiftTime(isPreShift ? shiftData.break1_end_time : shiftData.scheduled_break1_end_time),
        formatShiftTime(isPreShift ? shiftData.break2_start_time : shiftData.scheduled_break2_start_time),
        formatShiftTime(isPreShift ? shiftData.break2_end_time : shiftData.scheduled_break2_end_time),
      ];
    };

    const getAttendanceTypeName = (key: number | undefined) => {
      if (key === undefined) {
        return 'シフトなし';
      }
      const attendanceType = attendanceTypes.value.find((item) => item.key === String(key));
      return attendanceType ? attendanceType.name : '';
    };

    const formatShiftTime = (time: number | undefined | null) =>
      time !== undefined && time !== null ? formatTimeInteger(time, 'HH:MM:SS') : '';

    watch([baseDate], setDateRange);
    watch([isDateInvalid], () => {
      if (isDateInvalid.value) {
        notifyError1(root, '開始日を終了日よりも前の日に指定してください');
      }
    });

    watch(isDisplayShiftCsvOutputModal, () => {
      if (isDisplayShiftCsvOutputModal.value === false) {
        setDateRange();
      }
    });

    const datePickerOptions = {
      disabledDate(date: Date) {
        const formatDate = formatDateToDay(date);
        const startDate = baseFormattedDate.value;
        const endDate = addDays(baseFormattedDate.value, 9);

        return !(formatDate >= startDate && formatDate <= endDate);
      },
    };

    return {
      isDisplayShiftCsvOutputModal,
      closeShiftCsvOutputModal,
      state,
      csvState,
      CHECKED_SHIFT,
      datePickerOptions,
      beforeDownload,
      isDateInvalid,
    };
  },
});
