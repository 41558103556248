import { Ref, watch } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { isExist } from 'src/util/isExist';

type Params = {
  tableA: Ref<HTMLDivElement | undefined>;
  tableB: Ref<HTMLDivElement | undefined>;
};

type InjectionValue = {
  tableA: Ref<HTMLDivElement | undefined>;
  tableB: Ref<HTMLDivElement | undefined>;
};

const { provide, inject } = createInjection<InjectionValue>('useScrollSync');

export function useScrollSyncProvider({ tableA, tableB }: Params): void {
  const scrollSyncTableA = () => {
    if (!isExist(tableA.value)) {
      return;
    }
    tableA.value.scrollLeft = tableB.value?.scrollLeft ?? 0;
  };

  const scrollSyncTableB = () => {
    if (!isExist(tableB.value)) {
      return;
    }
    tableB.value.scrollLeft = tableA.value?.scrollLeft ?? 0;
  };

  watch(
    () => [tableA.value, tableB.value],
    ([tableAValue, tableBValue]) => {
      if (!isExist(tableAValue) || !isExist(tableBValue)) {
        return;
      }
      tableAValue.removeEventListener('scroll', scrollSyncTableB);
      tableBValue.removeEventListener('scroll', scrollSyncTableA);
      tableAValue.addEventListener('scroll', scrollSyncTableB);
      tableBValue.addEventListener('scroll', scrollSyncTableA);
    },
  );

  provide({
    tableA,
    tableB,
  });
}

export function useScrollSync(): InjectionValue {
  return inject();
}
