import { ref, type Ref } from '@vue/composition-api';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { createInjection } from 'src/util/createInjection';
import type { MacroOperationMaster } from 'src/models/macroOperationMaster';

type Params = {
  workplaceId: number;
};

type InjectionValue = {
  macroOperationMasters: Ref<MacroOperationMaster[]>;
  fetchMacroOperationMasters: (budgetGroupId: number, isEnabled: boolean) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useMacroOperationMasters');

export function useMacroOperationMastersProvider({ workplaceId }: Params): void {
  const errorBoundary = useErrorBoundary();
  const macroOperationMasters = ref<MacroOperationMaster[]>([]);

  const fetchMacroOperationMasters = errorBoundary.wrap(
    async (budgetGroupId: number, disabledItem: boolean) => {
      const MacroOperationMasterResponse = await macroOperationMasterApi.index({
        workplaceId: workplaceId,
        budgetGroupId: budgetGroupId,
        params: {
          ...(disabledItem ? null : { is_enabled: true }),
        },
      });

      macroOperationMasters.value = MacroOperationMasterResponse;
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  provide({
    macroOperationMasters: macroOperationMasters,
    fetchMacroOperationMasters,
  });
}

export function useMacroOperationMasters(): InjectionValue {
  return inject();
}
