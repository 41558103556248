import { type StaffShiftOverviewSummaryResponse } from 'src/models/api/staffShiftResponse';
import { parseYmdDate } from 'src/util/datetime';

export type StaffShiftOverviewSummary = {
  dt: Date;
  scheduled_attendance_count: number;
  actual_attendance_count: number;
  late_count: number;
  early_leave_count: number;
  absent_count: number;
  unregistered_count: number;
  no_break_count: number;
  over_two_hours_break_count: number;
};

export function convertStaffShiftOverviewSummaryResponse(
  response: StaffShiftOverviewSummaryResponse[],
): StaffShiftOverviewSummary[] {
  return response.map((v) => ({
    ...v,
    dt: parseYmdDate(v.dt),
  }));
}
