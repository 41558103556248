import { ref, readonly, type ComputedRef, type Ref, type DeepReadonly, computed } from '@vue/composition-api';

import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { createInjection } from 'src/util/createInjection';
import { useSearchConditions } from './useSearchConditions';
import { isExist } from 'src/util/isExist';
import { useDisplayConditions } from './useDisplayConditions';
import { Staff } from 'src/models/staff';

type InjectionValue = {
  staffs: DeepReadonly<Ref<Staff[]>>;
  staffIds: ComputedRef<number[]>;
  fetchStaffs: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffs');

export function useStaffsProvider(): void {
  const errorBoundary = useErrorBoundary();
  const staffs = ref<Staff[]>([]);
  const { workplaceId, budgetGroup } = useSearchConditions();
  const { macroOperationMaster, staffLabel, gender, skillOptions } = useDisplayConditions();

  const fetchStaffs = errorBoundary.wrap(
    async () => {
      if (!isExist(budgetGroup.value)) {
        return;
      }

      const staffsResponse = await staffExtensionApi.staffs({
        workplaceId: workplaceId,
        params: {
          budget_group_id: budgetGroup.value.id,
          macro_operation_master_id: macroOperationMaster.value?.id ?? null,
          staff_label_id: staffLabel.value?.id ?? null,
          staff_gender: gender.value,
          is_key_player: skillOptions.value[0].checked || null,
          is_forkman: skillOptions.value[1].checked || null,
          has_custom_skill1: skillOptions.value[2].checked || null,
          has_custom_skill2: skillOptions.value[3].checked || null,
          has_custom_skill3: skillOptions.value[4].checked || null,
          is_enabled: true,
        },
      });

      staffs.value = staffsResponse;
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  const staffIds = computed(() => staffs.value.map(({ id }) => id));

  provide({
    staffs: readonly(staffs),
    staffIds,
    fetchStaffs: fetchStaffs,
  });
}

export function useStaffs(): InjectionValue {
  return inject();
}
