import { StaffResponse } from 'src/models/api/staffResponse';
import { StaffAgencyResponse } from 'src/models/api/staffAgencyResponse';
import { StaffLabelResponse } from 'src/models/api/staffLabelResponse';
import { BudgetGroupResponse } from 'src/models/api/budgetGroupResponse';
import { TimeInteger } from 'src/models/common';
import { convertBudgetGroupResponse } from 'src/models/budgetGroup';
import { convertStaffLabelResponse } from 'src/models/staffLabel';
import { toNumber } from 'src/util/numbers';
import { convertStaffExtensionResponse, StaffExtension } from 'src/models/staffExtension';
import { convertStaffSkillResponse, StaffSkill } from 'src/models/staffSkill';
import { convertStaffShiftResponse, StaffShift } from 'src/models/staffShift';
import { convertStaffMonthlySummaryResponse, StaffMonthlySummary } from 'src/models/staffMonthlySummary';
import { convertMacroOperationMasterResponse, MacroOperationMaster } from './macroOperationMaster';

export interface Staff {
  id: number;
  workplace_id: number;
  staff_number: string;
  family_name: string;
  first_name: string;
  family_name_kana: string;
  first_name_kana: string;
  staff_agency_id: number;
  staff_agency?: StaffAgencyResponse;
  staff_label_id: number;
  staff_label?: StaffLabelResponse;
  budget_group_id: number;
  budget_group?: BudgetGroupResponse;
  hourly_cost: number;
  regular_start_time: TimeInteger;
  regular_end_time: TimeInteger;
  break_time: TimeInteger;
  standard_working_hours: TimeInteger;
  overtime_extra_rate: number;
  midnight_extra_rate: number;
  holiday_extra_rate: number;
  holidays: number[];
  midnight_time_start: TimeInteger | null;
  midnight_time_end: TimeInteger | null;
  is_enabled: boolean;
  staff_extension?: StaffExtension;
  staff_skills: StaffSkill[];
  staff_shifts: StaffShift[];
  staff_monthly_summaries: StaffMonthlySummary[];
  macro_operation_master: MacroOperationMaster | null;
}

export function convertStaffResponse(response: StaffResponse): Staff {
  const {
    staff_agency,
    staff_label,
    budget_group,
    staff_extension,
    staff_skills,
    staff_shifts,
    staff_monthly_summaries,
    macro_operation_master,
    ...rest
  } = response;
  return {
    ...rest,
    ...(staff_agency ? { staff_agency } : null),
    ...(staff_label ? { staff_label: convertStaffLabelResponse(staff_label) } : null),
    ...(budget_group ? { budget_group: convertBudgetGroupResponse(budget_group) } : null),
    ...(staff_extension ? { staff_extension: convertStaffExtensionResponse(staff_extension) } : null),
    overtime_extra_rate: toNumber(response.overtime_extra_rate),
    midnight_extra_rate: toNumber(response.midnight_extra_rate),
    holiday_extra_rate: toNumber(response.holiday_extra_rate),
    staff_skills: staff_skills?.map(convertStaffSkillResponse) ?? [],
    staff_shifts: staff_shifts?.map(convertStaffShiftResponse) ?? [],
    staff_monthly_summaries: staff_monthly_summaries?.map(convertStaffMonthlySummaryResponse) ?? [],
    macro_operation_master: staff_extension?.macro_operation_master
      ? convertMacroOperationMasterResponse(staff_extension.macro_operation_master)
      : null,
  };
}

interface AttendanceStatusTotalItem {
  total: number;
  items: { [key: string]: number }[];
}

export interface AttendanceStatus {
  attendances_count: AttendanceStatusTotalItem;
  late_attendances_count: AttendanceStatusTotalItem;
  early_attendances_count: AttendanceStatusTotalItem;
  work_hours: AttendanceStatusTotalItem;
  hours_overtime: AttendanceStatusTotalItem;
  hours_midnight: AttendanceStatusTotalItem;
  hours_holiday: AttendanceStatusTotalItem;
}
