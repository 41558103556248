import { ref, readonly, type Ref, type DeepReadonly, watch } from '@vue/composition-api';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { createInjection } from 'src/util/createInjection';
import { useSearchConditions } from './useSearchConditions';
import { isExist } from 'src/util/isExist';
import type { MacroOperationMaster } from 'src/models/macroOperationMaster';

type InjectionValue = {
  macroOperationMasters: DeepReadonly<Ref<MacroOperationMaster[]>>;
  fetchMacroOperationMasters: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useMacroOperationMasters');

export function useMacroOperationMastersProvider(): void {
  const errorBoundary = useErrorBoundary();
  const macroOperationMasters = ref<MacroOperationMaster[]>([]);
  const { workplaceId, budgetGroup } = useSearchConditions();

  const fetchMacroOperationMasters = errorBoundary.wrap(
    async () => {
      if (!isExist(budgetGroup.value)) {
        return;
      }

      const MacroOperationMasterResponse = await macroOperationMasterApi.index({
        workplaceId: workplaceId,
        budgetGroupId: budgetGroup.value.id,
        params: {
          is_enabled: true,
        },
      });

      macroOperationMasters.value = MacroOperationMasterResponse;
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  watch(budgetGroup, fetchMacroOperationMasters);

  provide({
    macroOperationMasters: readonly(macroOperationMasters),
    fetchMacroOperationMasters,
  });
}

export function useMacroOperationMasters(): InjectionValue {
  return inject();
}
