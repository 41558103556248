import { ref, watch, type Ref } from '@vue/composition-api';
import { type ProgressPlan } from 'src/models/progressPlan';
import { createInjection } from 'src/util/createInjection';

type Params = {
  progressPlan: Ref<ProgressPlan | null>;
};

type InjectionValue = {
  progressPlan: Ref<ProgressPlan | null>;
};

const { provide, inject } = createInjection<InjectionValue>('useProgressPlan');

export function useProgressPlanProvider({ progressPlan: initialProgressPlan }: Params): void {
  const progressPlan = ref<ProgressPlan | null>(initialProgressPlan.value);

  watch(initialProgressPlan, (newProgressPlan) => {
    progressPlan.value = newProgressPlan;
  });

  provide({
    progressPlan,
  });
}

export function useProgressPlan(): InjectionValue {
  return inject();
}
