import { ref, watch, computed, onBeforeUnmount, type Ref, type ComputedRef } from '@vue/composition-api';
import { format } from 'date-fns';
import { createInjection } from 'src/util/createInjection';
import { createTimer } from 'src/util/createTimer';
import progressHeaderApi from 'src/apis/progressHeader';
import progressPlanApi from 'src/apis/progressPlan';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useSearchConditions } from './useSearchConditions';
import {
  convertProgressHeaderPerformanceBoardDetailIndexResponse,
  type ProgressHeader,
} from 'src/models/progressHeader';
import {
  convertProgressPlanSubWorkplacePerformanceBoardList1Response,
  type ProgressPlan,
} from 'src/models/progressPlan';

type InjectionValue = {
  canSearch: Ref<boolean>;
  fetchProgresses: () => Promise<void>;
  progressHeaders: Ref<ProgressHeader[]>;
  progressPlansMap: ComputedRef<Record<number, ProgressPlan>>;
};

const { provide, inject } = createInjection<InjectionValue>('useProgressSet');

type Params = {
  workplace_id: number;
  dt: string;
  budget_group_id: number | null;
  timetable_label_ids: number[];
  timetable_master_ids: number[];
};

export const useProgressSetProvider = () => {
  const errorBoundary = useErrorBoundary();

  const progressHeaders = ref<ProgressHeader[]>([]);
  const progressPlans = ref<ProgressPlan[]>([]);
  const progressPlansMap = computed<Record<number, ProgressPlan>>(() => {
    return progressPlans.value.reduce<Record<number, ProgressPlan>>((acc, progressPlan) => {
      acc[progressPlan.timetableMasterId] = progressPlan;
      return acc;
    }, {});
  });

  const canSearch = ref(true);
  const shouldReload = ref(false);

  const { workplaceId, dt, budgetGroup, timetableLabels, timetableMasters } = useSearchConditions();
  const params = computed<Params>(() => ({
    workplace_id: workplaceId,
    dt: format(dt.value, 'yyyy-MM-dd'),
    budget_group_id: budgetGroup.value?.id ?? null,
    timetable_label_ids: timetableLabels.value.map(({ id }) => id),
    timetable_master_ids: timetableMasters.value.map(({ id }) => id),
  }));

  const fetchProgressHeaders = errorBoundary.wrap(
    async () => {
      const { data } = await progressHeaderApi.performanceBoardDetailIndex(params.value);
      progressHeaders.value = convertProgressHeaderPerformanceBoardDetailIndexResponse(data);
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  const fetchProgressPlans = errorBoundary.wrap(
    async () => {
      const { data } = await progressPlanApi.subWorkplacePerformanceBoardList1(params.value);
      progressPlans.value = convertProgressPlanSubWorkplacePerformanceBoardList1Response(data);
    },
    {
      fallbackMessage: '計画情報の取得に失敗しました',
    },
  );

  const fetchProgresses = async () => {
    try {
      canSearch.value = false;
      shouldReload.value = false;
      await Promise.all([fetchProgressHeaders(), fetchProgressPlans()]);
      shouldReload.value = true;
    } finally {
      canSearch.value = true;
    }
  };

  const autoReloadTimer = createTimer(fetchProgresses, 5 * 60 * 1000);

  watch([dt, budgetGroup, timetableLabels, timetableMasters], () => {
    shouldReload.value = false;
  });

  watch(shouldReload, () => {
    if (shouldReload.value) {
      autoReloadTimer.start();
    } else {
      autoReloadTimer.stop();
    }
  });

  onBeforeUnmount(() => {
    autoReloadTimer.stop();
  });

  provide({
    canSearch,
    fetchProgresses,
    progressHeaders,
    progressPlansMap,
  });
};

export function useProgressSet(): InjectionValue {
  return inject();
}
