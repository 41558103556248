





















import { defineComponent } from '@vue/composition-api';
import { CardPanel } from '../CardPanel';
import Progress from './Progress.vue';
import Difference from './Difference.vue';
import ManHours from './ManHours.vue';
import Productivity from './Productivity.vue';
import Headcount from './Headcount.vue';

export default defineComponent({
  components: {
    CardPanel,
    Progress,
    Difference,
    ManHours,
    Productivity,
    Headcount,
  },
});
