





















































































import { defineComponent, computed } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import KeyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import ForkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import CustomFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import CustomFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import CustomFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useLoadLogimeterStaffAttendances } from '../../composables/useLoadLogimeterStaffAttendances';
import { useMacroOperationMasters } from '../../composables/useMacroOperationMasters';
import { useSearchConditions } from '../../composables/useSearchConditions';
import { useStaffLabels } from 'src/composables/useStaffLabels';
import { Checkbox } from '../Checkbox';
import { DateRangeSelect } from '../DateRangeSelect';
import { MacroOperationMasterSelect } from '../MacroOperationMasterSelect';
import { ShiftStatusSelect } from '../ShiftStatusSelect';
import { StaffLabelSelect } from '../StaffLabelSelect';
import { WeekDaySelect } from '../WeekDaySelect';
import { useSearch } from './useSearch';
import { useWorkplaceExtension } from 'src/composables/useWorkplaceExtension';
import { useStaffShiftOverviewSummary } from '../../composables/useStaffShiftOverviewSummary';

export default defineComponent({
  components: {
    BudgetGroupSelect,
    DateRangeSelect,
    MacroOperationMasterSelect,
    ShiftStatusSelect,
    StaffLabelSelect,
    WeekDaySelect,
    Checkbox,
    KeyPlayerIcon,
    ForkliftIcon,
    CustomFlag1Icon,
    CustomFlag2Icon,
    CustomFlag3Icon,
    PrimaryButton,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups();
    const { macroOperationMasters } = useMacroOperationMasters();
    const { staffLabels } = useStaffLabels();
    const {
      dateRange,
      weekDays,
      budgetGroup,
      shiftStatus,
      macroOperationMasters: selectedMacroOperationMasters,
      staffLabels: selectedStaffLabels,
      isKeyPlayer,
      isForkman,
      hasCustomSkill1,
      hasCustomSkill2,
      hasCustomSkill3,
    } = useSearchConditions();
    const { hasCustomSkillName1, hasCustomSkillName2, hasCustomSkillName3 } = useWorkplaceExtension();
    const { isLoading, handleSearch } = useSearch();
    const { isVisibleStaffShiftOverviewSummary } = useStaffShiftOverviewSummary();
    const { showLogimeterStaffAttendancesLoadModal } = useLoadLogimeterStaffAttendances();
    const selectableMacroOperationMasters = computed(() => {
      return macroOperationMasters.value.filter((v) => v.budget_group_id === budgetGroup.value?.id);
    });

    return {
      budgetGroups,
      macroOperationMasters,
      staffLabels,
      dateRange,
      weekDays,
      budgetGroup,
      shiftStatus,
      selectedMacroOperationMasters,
      selectedStaffLabels,
      isKeyPlayer,
      isForkman,
      hasCustomSkill1,
      hasCustomSkill2,
      hasCustomSkill3,
      hasCustomSkillName1,
      hasCustomSkillName2,
      hasCustomSkillName3,
      isLoading,
      handleSearch,
      showLogimeterStaffAttendancesLoadModal,
      selectableMacroOperationMasters,
      isVisibleStaffShiftOverviewSummary,
    };
  },
});
