









import { PropType, defineComponent, toRef } from '@vue/composition-api';
import { useProgressDetailListModal } from '../../PerformanceBoardDetail/composables/useProgressDetailListModal';
import { type ProgressHeader } from 'src/models/progressHeader';

export default defineComponent({
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
    fetchProgressHeader: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
  },
  setup(props) {
    const { showProgressDetailListModal } = useProgressDetailListModal();
    const showProgressDetails = () => {
      showProgressDetailListModal({
        progressHeader: toRef(props, 'progressHeader'),
        fetchProgressHeader: props.fetchProgressHeader,
      });
    };
    return {
      showProgressDetails,
    };
  },
});
